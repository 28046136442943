// Primary Colors
$british-racing-green: rgb(7, 76, 49); // #074c31
$android-green: rgb(161, 192, 56); // #a1c038

// Text Colors
$black: rgb(0, 0, 0); //#000000
$raisin-black: rgb(38, 38, 38); // #262626
$onyx: rgb(56, 56, 56); //#383838
$davys-grey: rgb(89, 89, 89); // #595959
$gray-x11: rgb(191, 191, 191); // #bfbfbf
$white-smoke: rgb(245, 245, 245); // #f5f5f5

$snow: rgb(250, 250, 250); // #fafafa
$white: rgb(255, 255, 255); // #ffffff
$light-gray: rgb(214, 214, 214); // #d6d6d6
$gunmetal: rgb(42, 43, 68); //#2A2B44
$pattrick-blue: rgb(38, 61, 118); //#263D76
$dark-slate-blue: rgb(47, 68, 127); //#2F447F
$dark-midnight-blue: rgb(0, 57, 116); //#003974
$dark-midnight-blue2: rgb(0, 53, 108); //#00356c
$sapphire: rgb(0, 85, 184); //#0055B8
$space-cadet: rgb(28, 56, 89); //#1C3859
$turquoise: rgb(72, 232, 217); //#48E8D9
$dark-cerulean: rgb(33, 70, 113); //#214671
$uni-cali-gold: rgb(184, 126, 43); //#CEB780
$bronze: rgb(200, 140, 44); //#C88C2C
$dim-gray: rgb(109, 110, 113); //#6D6E71
$ku-crimson: rgb(224, 13, 13); //#e00d0d
$jet: rgb(51, 51, 51); //#333333
$satin-sheen-gold: rgb(207, 154, 62); //#CF9A3E
$oxford-blue: rgb(1, 25, 62); //#01193E
$gainsboro: rgb(222, 222, 222); //#DEDEDE
$jet: rgb(51, 51, 51); //#333333
$outer-space: rgb(67, 69, 73); //#434549
$red: rgb(255, 0, 0); //#FF0000
$liver-dogs: rgb(174, 108, 40); //#996633
$battleship-gray: rgb(133, 133, 133); //#858585
$anti-flash-white: rgb(242, 242, 242); //##F2F2F2
$gray-x112: rgb(189, 189, 189); //#BDBDBD
$bronze2: rgb(193, 133, 49); //#C18531
$uni-cali-gold2: rgb(192, 128, 42); //#c0802a
$spanish-gray: rgb(145, 145, 145); //#919191
$dark-liver: rgb(79, 79, 79); //#4F4F4F
$avocado: rgb(101, 158, 9); //#659e09
$yale-blue: rgb(27, 70, 133); //##1b4685
$x11-gray: rgb(188, 190, 192); //#bcbec0
$rich-black: rgb(14, 0, 30); //#000033
$light-brilliant-red: rgb(255, 41, 41); //#ff3333
$pastel-gray: rgb(201, 201, 201); //#c9c9c9
$dark-cerulean: rgb(2, 63, 136); //#023f88
$peru: rgb(188, 139, 66); //#bc8b42
$mellow-apricot: rgb(236, 188, 121); //#ecbc79
$california-gold: rgb(190, 128, 43); //#be802b

// Palette Colors
$amazon: rgb(57, 112, 90); // #39705a
$xanadu: rgb(106, 148, 131); // #6a9483
$aero-blue: rgb(207, 236, 225); // #cfece1
$green-ryb: rgb(118, 168, 38); // #76a826
$june-bud: rgb(180, 205, 96); // #b4cd60
$pale-goldenrod: rgb(217, 230, 175); // #d9e6af

// Toast Colors
$toast-green: rgb(96, 168, 66); // #60a842
$sinopia: rgb(206, 49, 0); // #ce3100
$vivid-amber: rgb(206, 142, 0); // #ce8e00
$true-blue: rgb(0, 95, 206); // #005fce

// Others
$urobilin: rgb(223, 179, 37); // #dfb325
$antique-bronze: rgb(103, 88, 34); // #675822
$plum: rgb(161, 66, 150); // #a14296
$cyber-grape: rgb(87, 58, 116); // #573a74
$bg-white-smoke: rgb(246, 246, 246); // #f6f6f6
$gray: rgb(128, 128, 128); // #808080
$forest-green: rgb(0, 68, 29); // #00441d
$spanish-green: rgb(0, 144, 71); // #009047
$north-texas-green: rgb(0, 137, 64); // #008940

// Overlays
$onyx055: rgba($onyx, 0.55);
$black-065: rgba($black, 0.65);
$black-025: rgba($black, 0.25);
$black-08: rgba($black, 0.8);

// Linear
$linear-border: linear-gradient(90deg,
#cab487 35.76%,
#a78657 42.2%,
#8c6131 48.27%,
#784716 53.87%,
#6c3806 58.83%,
#683200 62.63%,
#733e07 66.97%,
#905f19 74.9%,
#bf9435 85.45%,
#d2aa41 89.4%,
#d6b24f 90.95%,
#e2c673 93.85%,
#f4e7ac 97.77%,
#fffbd0 100%);
$linear-seal-brown: linear-gradient(90deg,
#d58800 -2422.54%,
#f2aa00 -2218.18%,
#c58013 -1754.45%,
#d38702 -925.69%,
#d58800 -848.79%,
#d7aa5c -388.95%,
#d7ad65 -343.24%,
#c88c2c 220.19%);
$linear-seal-brown2: linear-gradient(90deg, #ecbc79 0%, #f3d194 6.47%, #fff3bf 17.51%, #efdaa1 19.12%, #d9b878 21.64%, #c99f5a 23.93%, #bf9048 25.9%, #bc8b42 27.31%, #cca35f 30.25%, #e7ce93 36%, #f9e9b3 40.35%, #fff3bf 42.75%, #efdaa1 44.7%, #d9b878 47.78%, #c99f5a 50.58%, #bf9048 52.98%, #bc8b42 54.7%, #c6954d 56.37%, #e1b16d 61.93%, #ecbc79 65.01%, #f0c989 66.15%, #f8e0a7 68.62%, #fdeeb9 70.74%, #fff3bf 72.27%, #fff3bf 80.09%, #fbe6af 81.9%, #f3cf91 85.82%, #eec17f 89.2%, #ecbc79 91.63%);
$linear-yankees-blue: linear-gradient(180.13deg,
#337295 -30.88%,
#133f66 12.79%,
#16213a 79.96%);
$linear-seal-brown3: linear-gradient(0deg,
#502300 -7.2%,
#5b2f08 -6.08%,
#ad8a46 7.47%,
#e0c36d 17.63%,
#f4d97c 22.14%,
#fbeac2 52.62%,
#f4d97c 72.94%,
#eed377 77.45%,
#dfc16a 81.97%,
#c5a353 88.74%,
#a17a34 95.52%,
#73470d 102.29%,
#643500 104.55%);
$linear-seal-brown4: linear-gradient(90deg, #ceb780 0.01%, #c59148 16.98%, #dcbe88 32.86%, #ebddb3 44.62%, #e7d5a8 46.92%, #d5b074 58.6%, #c99a54 67.39%, #c59148 72.24%, #c48e42 75.74%, #c18430 89.83%, #c0802a 99.97%);
$linear-seal-brown5: linear-gradient(90deg, #c7b079 0%, #be8a49 16.98%, #d4b582 32.1%, #e4d5ad 44.64%, #e2d2a9 45.54%, #cfab75 57.86%, #c39355 67.15%, #be8a49 72.27%, #bd8843 75.77%, #b98131 89.87%, #b87e2b 100.02%);
$linear-seal-brown5a: linear-gradient(95.44deg,
#ceb780 2.5%, #c59147 18.63%, #dcbe87 33.72%, #ebdcb2 44.9%, #e7d4a7 47.09%, #d5b073 58.19%, #c99953 66.55%, #c59147 71.16%, #c48e42 73.96%, #c18430 87.66%, #c0802a 97.51%);

$linear-seal-brown6: linear-gradient(90deg,
#d58800 0%,
#f2aa00 7.73%,
#c58013 25.28%,
#d38702 56.64%,
#d58800 59.55%,
#d7aa5c 76.95%,
#d7ad65 78.68%,
#c88c2c 100%);

$linear-cali-gold: linear-gradient(90deg,
#c7b079 7950%,
#be8a49 9919.96%,
#d4b582 11658.16%,
#e4d5ad 13164.6%,
#e2d2a9 13280.48%,
#cfab75 14671.04%,
#c39355 15713.96%,
#be8a49 16293.36%,
#bd8843 16756.88%,
#b98131 18379.2%,
#b87e2b 19538%);
$linear-dark-cerulean: radial-gradient(83.72% 751.09% at 11.31% -131.52%,
#0055b8 0%,
#023f88 100%);
$linear-black-cerulean: linear-gradient(0deg, #30447f, #30447f),
    linear-gradient(0deg, #273d75, #273d75);
$linear-black-cerulean2: linear-gradient(#1c3859 100%, #000 100%);
$linear-black-cerulean3: linear-gradient(0deg, #1a4685, #1a4685),
    linear-gradient(0deg, #1c3859, #1c3859), linear-gradient(0deg, #000, #000);
$linear-black-cerulean4: linear-gradient(0deg, #2e427b, #2e427b),
    linear-gradient(0deg, #000, #000);
$linear-black-cerulean5: radial-gradient(54.33% 104.74% at 50% 49.99%, #0055b8 0%, #023f88 100%);
$linear-black-cerulean6: linear-gradient(126deg,
#337295 1.04%,
#133f66 26.64%,
#16213a 65.8%);
$linear-black-cerulean7: linear-gradient(62.58deg, #337295 0%, #133f66 26.14%, #16213a 66.14%);
;
$linear-black-cerulean8: linear-gradient(#337295 0%,
#133f66 26.14%,
#16213a 66.14%);
$linear-blue-footer: linear-gradient(180deg, #0a1e3f -675.91%, #083768 -589.91%, #064f8e -497.69%, #055da5 -423.42%, #0562ae -376.92%, #0d5792 -150.79%, #0d4f87 -98.31%, #0c3968 -4.75%, #0a183a 110.91%);
$linear-yellow: linear-gradient(#ecbc79 100%,
#f3d194 100%,
#fff3bf 100%,
#fff5c9 100%,
#fffef9 100%,
#fff 100%,
#fffbeb 100%,
#fff5cb 100%,
#fff3bf 100%,
#fffae7 100%,
#efdaa1 100%,
#d9b878 100%);
$inner-border-gold-linear: linear-gradient(64.5deg,
#75461c 0.46%,
#9f7b2d 12.47%,
#dcce88 24.68%,
#f3eeac 29.27%,
#dac880 47.45%,
#c0a068 58.6%,
#c7aa71 62.91%,
#d9c689 68.89%,
#f3eeac 79.33%,
#75461c 97.07%);
$linear-coyote: linear-gradient(#8b5f36 100%, #000 100%);
$linear-california-gold: linear-gradient(84.91deg,
#d4ad4f -0.21%,
#fcf8ce 24.94%,
#f8f0bd 32.99%,
#efda90 50.1%,
#edd586 53.12%,
#fee79a 75.26%,
#c0912d 100.41%);
$linear-california-gold2: linear-gradient(360deg,
#fee79a -48.21%,
#c0912d 100%);
$linear-gold2: linear-gradient(99.03deg,
#ecbc79 -155.98%,
#f3d194 -134.92%,
#fff3bf -92.81%,
#fff5c9 -89.3%,
#fffef9 -54.21%,
#fff -43.68%,
#fffbeb -33.15%,
#fff5cb -15.61%,
#fff3bf -5.08%,
#fffae7 -4.87%,
#efdaa1 1.94%,
#d9b878 12.47%,
#c99f5a 23%,
#bf9048 30.02%,
#bc8b42 37.04%,
#c6954d 40.55%,
#e1b16d 61.6%,
#ecbc79 72.13%,
#f0c989 75.64%,
#f8e0a7 86.17%,
#fdeeb9 93.19%,
#fff3bf 96.7%,
#fff3bf 124.77%,
#fbe6af 131.79%,
#f3cf91 145.83%,
#eec17f 156.36%,
#ecbc79 166.88%);
$linear-gold3: linear-gradient(138.08deg,
#ecbc79 -194.03%,
#f3d194 -168.89%,
#fff3bf -118.62%,
#fff5c9 -114.43%,
#fffef9 -72.53%,
#fff -59.96%,
#fffbeb -47.39%,
#fff5cb -26.45%,
#fff3bf -13.88%,
#fffae7 -13.63%,
#efdaa1 -5.5%,
#d9b878 7.07%,
#c99f5a 19.64%,
#bf9048 28.02%,
#bc8b42 36.4%,
#c6954d 40.59%,
#e1b16d 65.72%,
#ecbc79 78.29%,
#f0c989 82.48%,
#f8e0a7 95.05%,
#fdeeb9 103.43%,
#fff3bf 107.62%,
#fff3bf 141.13%,
#fbe6af 149.51%,
#f3cf91 166.27%,
#eec17f 178.84%,
#ecbc79 191.41%);
$linear-finished: linear-gradient(138.08deg,
#ecbc79 -194.03%,
#f3d194 -168.89%,
#fff3bf -118.62%,
#fff5c9 -114.43%,
#fffef9 -72.53%,
#fff -59.96%,
#fffbeb -47.39%,
#fff5cb -26.45%,
#fff3bf -13.88%,
#fffae7 -13.63%,
#efdaa1 -5.5%,
#d9b878 7.07%,
#c99f5a 19.64%,
#bf9048 28.02%,
#bc8b42 36.4%,
#c6954d 40.59%,
#e1b16d 65.72%,
#ecbc79 78.29%,
#f0c989 82.48%,
#f8e0a7 95.05%,
#fdeeb9 103.43%,
#fff3bf 107.62%,
#fff3bf 141.13%,
#fbe6af 149.51%,
#f3cf91 166.27%,
#eec17f 178.84%,
#ecbc79 191.41%);
$linear-survey-title: linear-gradient(138.08deg,
#ecbc79 -194.03%,
#f3d194 -168.89%,
#fff3bf -118.62%,
#fff5c9 -114.43%,
#fffef9 -72.53%,
#fff -59.96%,
#fffbeb -47.39%,
#fff5cb -26.45%,
#fff3bf -13.88%,
#fffae7 -13.63%,
#efdaa1 -5.5%,
#d9b878 7.07%,
#c99f5a 19.64%,
#bf9048 28.02%,
#bc8b42 36.4%,
#c6954d 40.59%,
#e1b16d 65.72%,
#ecbc79 78.29%,
#f0c989 82.48%,
#f8e0a7 95.05%,
#fdeeb9 103.43%,
#fff3bf 107.62%,
#fff3bf 141.13%,
#fbe6af 149.51%,
#f3cf91 166.27%,
#eec17f 178.84%,
#ecbc79 191.41%);

$linear-gold-new: linear-gradient(90deg, #ecbc79 0%, #fff3bf 18.18%, #bc8b42 34.9%, #fff3bf 52.34%, #bc8b42 67.56%, #fff3bf 80.44%, #ecbc79 100%);
$linear-gold-new1: linear-gradient(90deg, #ecbc79 0%, #bc8b42 27.64%, #ecbc79 52.34%, #bc8b42 75.01%, #ecbc79 100%);
$linear-blue-text: linear-gradient(90deg, #123f66 0%, #2f6b8e 30.21%, #0c4980 50.37%, #2f6b8e 72.4%, #123f66 100%);
$linear-orange-title: linear-gradient(87deg, #ecbc79 0.57%, #bc8b42 7.36%, #edd79d 25.89%, #fff3bf 51%, #ecd69c 75.3%, #bc8b42 88.26%, #ecbc79 98.19%);
$linear-orange-devider: linear-gradient(90deg, rgba(236, 188, 121, 0) 0%, rgba(255, 243, 191, 0.5) 20.83%, #ecbc79 51.56%, rgba(255, 243, 191, 0.5) 80.44%, rgba(236, 188, 121, 0) 100%);

// overlay
$pattrick-blue08: rgba($pattrick-blue, 0.8);
