.t-background {
    $root: &;
    overflow: hidden;
    background-color: $white;
    // height: 100% !important;
    position: relative;
    width: 100%;
    height: 100%;

    @include iphone-large-lands {
        overflow-y: auto;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        background-image: url('~assets/images/bg_desktop_phase3.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;

        @include mobile-down {
            background-image: url('~assets/images/register/line_3mau.png'), linear-gradient(#fff 50%, #e5ebeb 74%, #e5ebeb calc(100% - 57px), #fff calc(100% - 57px));
        }

        @include iphone-large-lands {
            background-image: url('~assets/images/bg_desktop_phase3.jpg');
            background-size: contain;
        }
    }

    &-isBackgroundPhase3 {
        position: relative;
        z-index: 2;
        // background: linear-gradient(#ffffff 67%, #ffffff 74%, #E5EBEB 90%, #E5EBEB 100%);

        // &::after {
        //     position: absolute;
        //     width: 100%;
        //     height: 100%;
        //     bottom: 0;
        //     right: 0;
        //     z-index: 2;
        //     background-image: url('~assets/images/register/line_3mau.png') !important;
        // }
    }

    &_content {
        position: relative;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        background-size: 100% 100%;
        z-index: 1;
        background-position: center;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding-bottom: rem(80);
        background-image: url('~assets/images/register/background_desktop_2.png');
        background-repeat: no-repeat;
        max-width: 100%;

        &::after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            background: linear-gradient(rgba(255, 255, 255, 0.4) 67%, rgba(255, 255, 255, 0.4) 74%, rgba(255, 255, 255, 0) 100%, #e5ebeb 100%);
        }

        @include max-large-desktop-down {
            padding-bottom: rem(40);
        }

        // @include max-desktop-down {
        //     padding-top: rem(100);
        // }

        @include desktop-down {
            padding-bottom: rem(40);
        }

        @include tablet-down {
            background-image: url('~assets/images/register/background_2.png');
            background-position: center bottom;
            max-width: rem(674);
            padding-bottom: rem(30);
            // margin-top: rem(131);
            // padding-top: 0;
        }

        @include iphone-large-lands {
            // padding-top: rem(100);
            min-height: 100vh;
            // height: auto !important;
            background-position: center;
            background-image: url('~assets/images/register/background_desktop_2.png');
        }

        &-isBackground1 {
            background-image: url('~assets/images/register/background.png');

            @include tablet-up {
                background-image: url('~assets/images/register/background_desktop.png');
            }

            @include iphone-large-lands {
                background-image: url('~assets/images/register/background_desktop.png');
                justify-content: flex-end;
            }
        }

        &-isBackgroundXN2 {
            background-image: url('~assets/images/register/background_mobile_xt2.png') !important;

            @include tablet-up {
                background-image: url('~assets/images/register/background_desktop_xn2.png') !important;
            }

            @include iphone-large-lands {
                background-image: url('~assets/images/register/background_desktop_xn2.png') !important;
                justify-content: flex-end !important;
            }
        }

        &-isBackgroundXN2Main {
            background-image: url('~assets/images/register/background_mobile_xt2_1.png') !important;

            @include tablet-up {
                background-image: url('~assets/images/register/background_desktop_xn2_main.png') !important;
            }

            @include iphone-large-lands {
                background-image: url('~assets/images/register/background_desktop_xn2_main.png') !important;
                justify-content: flex-end !important;
            }
        }

        &-isBackground3 {
            // padding-top: rem(140);
            padding-bottom: rem(80);
            background-image: url('~assets/images/register/background.png') !important;

            // @include max-large-desktop-down {
            //     padding-top: rem(140);
            // }

            // @include max-desktop-down {
            //     padding-top: rem(120);
            // }

            @include tablet-up {
                background-image: url('~assets/images/register/background_desktop.png') !important;
            }

            // @include tablet-down {
            //     margin-top: rem(100);
            //     padding-top: 0;
            // }

            @include iphone-large-lands {
                // padding-top: rem(80);
                background-image: url('~assets/images/register/background_desktop.png') !important;
                justify-content: flex-end !important;
            }
        }

        &-isBackground31 {
            // padding-top: rem(140);
            padding-bottom: rem(80);
            background-image: url('~assets/images/register/background_power1.png') !important;

            @include max-desktop-down {
                padding-bottom: rem(30);
            }

            @include tablet-up {
                background-image: url('~assets/images/register/background_desktop_31.jpg') !important;
            }

            // @include tablet-down {
            //     margin-top: rem(100);
            //     padding-top: 0;
            // }

            @include iphone-large-lands {
                // padding-top: rem(80);
                background-image: url('~assets/images/register/background_desktop_31.jpg') !important;
                justify-content: flex-end !important;
            }
        }

        &-isBackgroundPhase3 {
            background: transparent;

            &::after {
                @include mobile-up {
                    display: none;
                }
            }
        }
    }

    &_button {
        display: flex;
        justify-content: center;
        gap: rem(40);
        align-items: center;
        position: absolute;
        bottom: rem(32);
        left: 50%;
        transform: translate(-50%, 0);
        width: 100%;
        z-index: 2;

        @include max-desktop-down {
            bottom: rem(35);
        }

        @include tablet-down {
            bottom: rem(42);
        }

        @include iphone-large-lands {
            display: none;
        }

        &-horizontal {
            display: none;

            @include iphone-large-lands {
                display: flex;
                position: relative;
                left: auto;
                gap: rem(40);
                transform: none;
                bottom: auto;
                width: fit-content;
                margin: rem(40) auto;
            }
        }

        .a-button {
            @include max-desktop-down {
                @include font-base(24, 32);
            }

            @include tablet-down {
                @include font-base(16, 24);
            }

            @include mobile-down {
                @include font-base(14, 20);
            }
        }

        .a-button_icon {
            width: rem(34);
            height: rem(39);
            margin-right: rem(10);

            .a-icon {
                width: 100%;
                height: 100%;
            }

            @include max-desktop-down {
                width: rem(20);
                height: rem(22);
            }

            @include tablet-down {
                width: rem(16);
                height: rem(18);
            }
        }
    }
}
