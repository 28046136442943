.o-voucher {
    $root: &;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: rem(40);
    background-image: url('~assets/images/background_voucher.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;

    @include max-desktop-down {
        min-height: rem(32);
    }

    @include desktop-down {
        min-height: rem(30);
    }

    @include tablet-down {
        min-height: rem(28);
    }

    &_notify {
        display: flex;
        align-items: center;

        &_text {
            padding-left: rem(24);
            height: rem(62);
            width: 100%;
            border-top-right-radius: rem(16);
            border-bottom-right-radius: rem(16);
            background: #000;
            opacity: 0.65;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 0;
            margin-left: rem(-20);
            font-family: $font-gmv-din-pro-cond;
            font-weight: normal;
            @include font-base(24, 32);
            text-align: left;

            @include max-large-desktop-down {
                height: rem(58);
                @include font-base(18, 21);
            }

            @include max-desktop-down {
                height: rem(40);
                @include font-base(16, 18);
            }

            @include desktop-down {
                height: rem(36);
                @include font-base(14, 16);
            }

            @include tablet-down {
                height: rem(24);
                @include font-base(11, 14);
            }

            @include iphone-large-lands {
                @include font-base(9, 12);
                height: rem(28);
                padding-left: rem(23);
            }
        }

        &-success {
            .iconWarning {
                overflow: unset;
                background: #fff;
                border: 2.15px solid #f00;

                @include desktop-down {
                    width: rem(32);
                    height: rem(32);
                }

                .a-icon-checkedGreen {
                    margin-top: rem(-30);
                    margin-left: rem(20);
                    width: rem(40);
                    height: rem(60);
                    flex-shrink: 0;
                    // border: ;
                }
            }

            #{$root}_notify_text {
                color: #f00;
                background-color: $white;
                opacity: 1;
            }
        }
    }

    &-used {
        filter: grayscale(100%);
        opacity: 0.4;
    }

    &_left {
        padding: rem(2) rem(10) rem(8);
        position: relative;

        @include max-large-desktop-down {
            padding: rem(2) rem(8) rem(8);
        }

        @include desktop-down {
            padding: rem(2) rem(2) rem(8);
        }

        .a-image {
            z-index: 2;
            width: rem(60);
            height: rem(60);

            img {
                object-fit: contain;
            }

            @include max-large-desktop-down {
                width: rem(60);
                height: rem(60);
            }

            @include max-desktop-down {
                width: rem(50);
                height: rem(50);
            }

            @include desktop-down {
                width: rem(40);
                height: rem(40);
            }

            @include tablet-down {
                width: rem(30);
                height: rem(30);
            }
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 90%;
            background-image: url('~assets/icons/ic_circle_voucher.jpg');
            background-repeat: no-repeat;
            background-size: 130%;
            background-position: center;
            z-index: 1;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: -1px;
            width: rem(2);
            height: 90%;
            background: url('~assets/images/line_voucher.png') no-repeat;
            background-size: 100% 100%;

            @include tablet-down {
                height: 80%;
            }
        }
    }

    &_right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-left: rem(10);
        // padding-left: rem(30);

        // @include max-desktop-down {
        //   padding-left: rem(2);
        // }

        @include desktop-down {
            padding-left: rem(4);
        }

        &_phoneText {
            font-family: $font-din-engschrift;
            font-weight: normal;
            @include font-base(20, 28);
            color: #008e47;

            @include max-large-desktop-down {
                @include font-base(18, 21);
            }

            @include max-desktop-down {
                @include font-base(14, 21);
            }

            @include desktop-down {
                @include font-base(12, 16);
            }

            @include tablet-down {
                @include font-base(10, 14);
            }

            @include iphone-medium-lands {
                @include font-base(8, 12);
            }
        }

        &_price {
            font-family: $font-montserrat-black;
            font-weight: normal;
            @include font-base(28, 32);
            letter-spacing: 0.01em;
            text-align: left;
            color: #f00023;

            @include max-large-desktop-down {
                @include font-base(21, 24);
            }

            @include desktop-down {
                @include font-base(14, 18);
            }

            @include tablet-down {
                @include font-base(12, 18);
            }

            @include iphone-medium-lands {
                @include font-base(10, 12);
            }

            &-other {
                font-family: $font-din-engschrift;

                @include max-large-desktop-down {
                    @include font-base(21, 25);
                }

                @include max-desktop-down {
                    @include font-base(14, 18);
                }

                @include desktop-down {
                    @include font-base(12, 16);
                }
            }
        }
    }

    &_save {
        position: absolute;
        height: 100%;
        top: 0;
        right: 0;
        width: calc(120 / 540 * 100%);
        min-width: rem(60);
        flex-shrink: 0;
        font-family: $font-montserrat-regular;
        font-weight: bold;
        @include font-base(19, 24);
        text-align: left;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 0;
        background: transparent;

        @include max-large-desktop-down {
            @include font-base(16, 18);
        }

        @include max-desktop-down {
            @include font-base(14, 18);
        }

        @include desktop-down {
            width: calc(80 / 330 * 100%);
        }

        @include tablet-down {
            @include font-base(12, 16);
            // width: calc(50 / 220 * 100%);
        }

        @include iphone-large-lands {
            @include font-base(10, 14);
            width: calc(50 / 220 * 100%);
            min-width: rem(40);
        }

        &:disabled {
            cursor: not-allowed;
        }
    }

    &-isSave {
        background-image: url('~assets/images/background_voucher_issave.png');

        #{$root}_left {
            height: 100%;
            padding: rem(2) rem(10);

            @include max-large-desktop-down {
                padding: rem(2) rem(8);
            }

            @include desktop-down {
                padding: rem(2);
            }

            &::before {
                height: 100%;
            }

            &::after {
                @include tablet-down {
                    height: 100%;
                }
            }

            // .a-image {
            //     &::before {
            //         height: 100%;
            //     }
            // }
        }
    }

    // phase 2
    &_phase2 {
        $phase2: &;
        margin-left: auto;
        background-image: url('~assets/images/voucher/background_green_in_cart.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        flex-direction: column;
        min-height: auto;
        width: 75%;
        height: rem(80);
        position: relative;
        margin-right: rem(24);

        @include max-large-desktop-down {
            width: 70%;
            height: rem(70);
        }

        @include max-desktop-down {
            width: 68%;
            height: rem(60);
        }

        @include desktop-down {
            width: 67%;
            margin-right: rem(8);
        }

        @include tablet-down {
            height: rem(42);
            margin-right: rem(4);
        }

        @include iphone-medium-lands {
            width: 64%;
        }

        &-disabled {
            pointer-events: none;
            opacity: 0.8;
            cursor: not-allowed;
        }

        &_cart {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &_top {
                position: relative;
                z-index: 1;
                width: calc(100% - 7px);
                height: calc(100% - 7px);
                border-radius: rem(12);
                overflow: hidden;

                @include max-large-desktop-down {
                    width: calc(100% - 5px);
                    height: calc(100% - 5px);
                    border-radius: rem(8);
                }

                @include desktop-down {
                    border-radius: rem(6);
                }

                @include tablet-down {
                    width: calc(100% - 3px);
                    height: calc(100% - 3px);
                }

                &::before {
                    position: absolute;
                    content: '';
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    height: 100%;
                    background-image: url('~assets/images/voucher/bg_top.svg');
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                }

                &_title {
                    position: relative;
                    z-index: 2;
                    font-family: $font-gmv-din-pro-cond-black;
                    font-weight: normal;
                    @include font-base(23, 28);
                    color: $white;
                    width: 95%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: auto;
                    text-align: center;
                    padding-right: rem(8);
                    padding-left: rem(8);

                    @include max-large-desktop-down {
                        width: 90%;
                        @include font-base(21, 24);
                    }

                    @include max-desktop-down {
                        @include font-base(18, 21);
                    }

                    @include desktop-down {
                        @include font-base(16, 18);
                    }

                    @include tablet-down {
                        @include font-base(14, 18);
                    }
                }
            }

            &_bottom {
                position: relative;
                z-index: 1;
                width: calc(100% - 8px);
                height: 100%;
                border-radius: rem(12);
                overflow: hidden;

                @include desktop-down {
                    border-radius: rem(8);
                }

                &::before {
                    position: absolute;
                    content: '';
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    height: 100%;
                    background-image: url('~assets/images/voucher/bg_bottom.svg');
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                }

                &_save {
                    border: 0;
                    background: transparent;
                    position: relative;
                    z-index: 2;
                    font-family: $font-inter;
                    font-weight: 700;
                    @include font-base(54, 72);
                    color: #009343;
                    width: 80%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: auto;

                    @include max-large-desktop-down {
                        @include font-base(41, 52);
                    }

                    @include max-desktop-down {
                        @include font-base(38, 42);
                    }

                    @include desktop-down {
                        @include font-base(24, 32);
                    }

                    @include tablet-down {
                        width: 90%;
                        @include font-base(21, 24);
                    }
                }
            }
        }

        &_thumbnail {
            position: absolute;
            top: 45%;
            left: rem(-48);
            transform: translateY(-50%);
            width: rem(100);
            height: 130%;
            z-index: 2;

            img {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }

            @include max-large-desktop-down {
                width: rem(80);
                left: rem(-40);
            }

            @include max-desktop-down {
                width: rem(70);
                left: rem(-35);
            }

            @include desktop-down {
                width: rem(60);
                left: rem(-30);
            }

            @include tablet-down {
                width: rem(40);
                left: rem(-20);
            }
        }

        &-isSave {
            width: calc(305 / 540 * 100%);
            height: rem(260);
            background-size: 100% 100%;
            background-image: url('~assets/images/voucher/background_green.png');
            position: relative;
            margin-right: 0;
            cursor: pointer;

            @include max-large-desktop-down {
                height: rem(220);
            }

            @include max-desktop-down {
                height: rem(150);
            }

            @include desktop-down {
                height: rem(120);
            }

            @include tablet-down {
                width: calc(320 / 540 * 100%);
                height: rem(110);
            }

            @include iphone-large-lands {
                height: rem(80);
            }

            @include mobile-lands {
                height: rem(70);
            }

            #{$phase2}_cart {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                &_top {
                    position: relative;
                    z-index: 1;
                    width: calc(100% - 8px);
                    height: calc(58% - 4px);
                    border-radius: rem(12);
                    overflow: hidden;

                    @include desktop-down {
                        border-radius: rem(8);
                    }

                    @include iphone-large-lands {
                        border-radius: rem(4);
                    }

                    &::before {
                        position: absolute;
                        content: '';
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 100%;
                        height: 100%;
                        background-image: url('~assets/images/voucher/bg_top.svg');
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                    }

                    &_title {
                        position: relative;
                        z-index: 2;
                        font-family: $font-gmv-din-pro-cond-black;
                        font-weight: normal;
                        @include font-base(34, 41);
                        color: $white;
                        width: 80%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-left: auto;
                        text-align: center;
                        padding-right: rem(6);

                        @include max-large-desktop-down {
                            @include font-base(32, 38);
                        }

                        @include max-desktop-down {
                            @include font-base(21, 28);
                            width: 84%;
                        }

                        @include desktop-down {
                            @include font-base(18, 21);
                            width: 85%;
                        }

                        @include iphone-large-lands {
                            @include font-base(13, 15);
                            width: 90%;
                        }

                        @include mobile-lands {
                            @include font-base(11, 13);
                        }
                    }
                }

                &_bottom {
                    position: relative;
                    z-index: 1;
                    width: calc(100% - 8px);
                    height: calc(42% - 4px);
                    border-radius: rem(12);
                    overflow: hidden;

                    @include desktop-down {
                        border-radius: rem(8);
                    }

                    @include iphone-large-lands {
                        border-radius: rem(4);
                    }

                    &::before {
                        position: absolute;
                        content: '';
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 100%;
                        height: 100%;
                        background-image: url('~assets/images/voucher/bg_bottom.svg');
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                    }

                    &_save {
                        border: 0;
                        background: transparent;
                        position: relative;
                        z-index: 2;
                        font-family: $font-inter;
                        font-weight: 700;
                        @include font-base(54, 72);
                        color: #009343;
                        width: 80%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-left: auto;

                        @include max-large-desktop-down {
                            @include font-base(41, 52);
                        }

                        @include max-desktop-down {
                            @include font-base(38, 42);
                        }

                        @include desktop-down {
                            @include font-base(24, 32);
                        }

                        @include tablet-down {
                            width: 90%;
                            @include font-base(21, 24);
                        }

                        @include iphone-large-lands {
                            @include font-base(14, 16);
                        }

                        @include mobile-lands {
                            @include font-base(12, 14);
                        }
                    }
                }
            }

            #{$phase2}_thumbnail {
                position: absolute;
                top: 50%;
                left: rem(-80);
                transform: translateY(-50%);
                width: rem(150);
                height: 90%;
                z-index: 2;

                @include max-large-desktop-down {
                    width: rem(120);
                    left: rem(-60);
                }

                @include max-desktop-down {
                    width: rem(100);
                    left: rem(-50);
                }

                @include desktop-down {
                    width: rem(80);
                    left: rem(-40);
                }

                @include tablet-down {
                    width: rem(70);
                    left: rem(-40);
                }

                @include iphone-large-lands {
                    width: rem(60);
                    left: rem(-30);
                }

                @include mobile-lands {
                    width: rem(55);
                    left: rem(-25);
                }

                img {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    &_phase3 {
        $phase3: &;
        margin-left: auto;
        background-image: url('~assets/images/voucher/background_green_in_cart.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        flex-direction: column;
        min-height: auto;
        width: 70%;
        height: rem(86);
        position: relative;
        margin-right: rem(24);

        @include max-large-desktop-down {
            height: rem(75);
        }

        @include max-desktop-down {
            width: 63%;
            height: rem(64);
        }

        @include desktop-down {
            width: 67%;
            margin-right: rem(8);
        }

        @include tablet-down {
            width: 61%;
            height: rem(48);
            margin-right: rem(4);
        }

        @include iphone-large-lands {
            width: 69%;
        }

        @include mobile-lands {
            width: 67%;
        }

        &-disabled {
            pointer-events: none;
            opacity: 0.8;
            cursor: not-allowed;
        }

        &_cart {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &_top {
                position: relative;
                z-index: 1;
                width: calc(100% - 7px);
                height: calc(100% - 7px);
                border-radius: rem(12);
                overflow: hidden;

                @include max-large-desktop-down {
                    width: calc(100% - 5px);
                    height: calc(100% - 5px);
                    border-radius: rem(8);
                }

                @include desktop-down {
                    border-radius: rem(6);
                }

                @include tablet-down {
                    width: calc(100% - 3px);
                    height: calc(100% - 3px);
                }

                &::before {
                    position: absolute;
                    content: '';
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    height: 100%;
                    background-image: url('~assets/images/voucher/bg_top.svg');
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                }

                &_title {
                    position: relative;
                    z-index: 2;
                    font-family: $font-gmv-din-pro-cond-black;
                    font-weight: normal;
                    @include font-base(23, 28);
                    color: $white;
                    width: 93%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: auto;
                    text-align: center;
                    padding-right: rem(8);
                    padding-left: rem(8);

                    @include max-large-desktop-down {
                        @include font-base(21, 24);
                    }

                    @include max-desktop-down {
                        @include font-base(18, 21);
                    }

                    @include desktop-down {
                        @include font-base(16, 18);
                    }

                    @include tablet-down {
                        @include font-base(14, 18);
                    }
                }
            }

            &_bottom {
                position: relative;
                z-index: 1;
                width: calc(100% - 8px);
                height: 100%;
                border-radius: rem(12);
                overflow: hidden;

                @include desktop-down {
                    border-radius: rem(8);
                }

                &::before {
                    position: absolute;
                    content: '';
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    height: 100%;
                    background-image: url('~assets/images/voucher/bg_bottom.svg');
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                }

                &_save {
                    border: 0;
                    background: transparent;
                    position: relative;
                    z-index: 2;
                    font-family: $font-inter;
                    font-weight: 700;
                    @include font-base(54, 72);
                    color: #009343;
                    width: 80%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: auto;

                    @include max-large-desktop-down {
                        @include font-base(41, 52);
                    }

                    @include max-desktop-down {
                        @include font-base(38, 42);
                    }

                    @include desktop-down {
                        @include font-base(24, 32);
                    }

                    @include tablet-down {
                        width: 90%;
                        @include font-base(21, 24);
                    }
                }
            }
        }

        &_thumbnail {
            position: absolute;
            top: 45%;
            left: rem(-48);
            transform: translateY(-50%);
            width: rem(100);
            height: 130%;
            z-index: 2;

            img {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }

            @include max-large-desktop-down {
                width: rem(80);
                left: rem(-40);
            }

            @include max-desktop-down {
                width: rem(70);
                left: rem(-35);
            }

            @include desktop-down {
                width: rem(70);
                left: rem(-30);
            }

            @include tablet-down {
                width: rem(50);
                left: rem(-20);
            }
        }

        &-isSave {
            width: calc(360 / 540 * 100%);
            height: rem(260);
            background-size: 100% 100%;
            background-image: url('~assets/images/voucher/background_green.png');
            position: relative;
            margin-right: 0;
            cursor: pointer;

            @include max-large-desktop-down {
                height: rem(220);
            }

            @include max-desktop-down {
                height: rem(180);
            }

            @include desktop-down {
                width: calc(350 / 540 * 100%);
                height: rem(140);
            }

            @include medium-desktop-down {
                width: calc(370 / 540 * 100%);
            }

            @include tablet-down {
                width: calc(360 / 540 * 100%);
                height: rem(110);
            }

            @include iphone-large-lands {
                height: rem(100);
            }

            @include mobile-lands {
                height: rem(94);
                width: calc(380 / 540 * 100%);
            }

            @include iphone-medium-lands {
                width: calc(350 / 540 * 100%);
            }

            #{$phase3}_cart {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                &_top {
                    position: relative;
                    z-index: 1;
                    width: calc(100% - 8px);
                    height: calc(58% - 4px);
                    border-radius: rem(12);
                    overflow: hidden;

                    @include desktop-down {
                        border-radius: rem(8);
                    }

                    @include iphone-large-lands {
                        border-radius: rem(4);
                    }

                    &::before {
                        position: absolute;
                        content: '';
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 100%;
                        height: 100%;
                        background-image: url('~assets/images/voucher/bg_top.svg');
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                    }

                    &_title {
                        position: relative;
                        z-index: 2;
                        font-family: $font-gmv-din-pro-cond-black;
                        font-weight: normal;
                        @include font-base(26, 32);
                        color: $white;
                        width: 95%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-left: auto;
                        text-align: center;
                        padding-right: rem(6);

                        @include max-large-desktop-down {
                            @include font-base(24, 28);
                        }

                        @include max-desktop-down {
                            @include font-base(21, 24);
                        }

                        @include desktop-down {
                            @include font-base(16, 18);
                        }

                        @include medium-desktop-down {
                            @include font-base(15, 17);
                            width: 92%;
                        }

                        @include tablet-down {
                            @include font-base(14, 16);
                        }

                        @include iphone-large-lands {
                            @include font-base(12, 15);
                            width: 92%;
                        }

                        @include mobile-lands {
                            @include font-base(11, 13);
                            width: 90%;
                        }
                    }
                }

                &_bottom {
                    position: relative;
                    z-index: 1;
                    width: calc(100% - 8px);
                    height: calc(42% - 4px);
                    border-radius: rem(12);
                    overflow: hidden;

                    @include desktop-down {
                        border-radius: rem(8);
                    }

                    @include iphone-large-lands {
                        border-radius: rem(4);
                    }

                    &::before {
                        position: absolute;
                        content: '';
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 100%;
                        height: 100%;
                        background-image: url('~assets/images/voucher/bg_bottom.svg');
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                    }

                    &_save {
                        border: 0;
                        background: transparent;
                        position: relative;
                        z-index: 2;
                        font-family: $font-montserrat-black;
                        font-weight: 700;
                        @include font-base(54, 72);
                        color: #009343;
                        width: 96%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-left: auto;

                        @include max-large-desktop-down {
                            @include font-base(41, 52);
                        }

                        @include max-desktop-down {
                            @include font-base(38, 42);
                        }

                        @include desktop-down {
                            @include font-base(24, 32);
                        }

                        @include tablet-down {
                            width: 90%;
                            @include font-base(21, 24);
                        }

                        @include iphone-large-lands {
                            width: 94%;
                            @include font-base(16, 18);
                        }

                        @include mobile-lands {
                            @include font-base(14, 16);
                        }
                    }
                }
            }

            #{$phase3}_thumbnail {
                position: absolute;
                top: 50%;
                left: rem(-120);
                transform: translateY(-50%);
                width: rem(200);
                height: 94%;
                z-index: 2;

                @include max-large-desktop-down {
                    width: rem(190);
                }

                @include max-desktop-down {
                    width: rem(170);
                    left: rem(-100);
                }

                @include desktop-down {
                    width: rem(120);
                    left: rem(-70);
                }

                @include tablet-down {
                    width: rem(100);
                    left: rem(-40);
                }

                @include iphone-large-lands {
                    width: rem(80);
                    left: rem(-42);
                }

                @include mobile-lands {
                    width: rem(65);
                    left: rem(-32);
                }

                img {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
