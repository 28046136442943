.p-home {
    &_agenda {
        padding: rem(60) 0 rem(30);
        position: relative;
        z-index: $z-1;

        @include tablet-down {
            padding: rem(40) 0 rem(20);
  
        }
    }
  
    &_testimonials {
        padding: rem(60) 0 rem(30);
        position: relative;
        z-index: $z-2;
  
        @include tablet-down {
            padding: rem(40) 0 rem(20);
        }

        @media (max-width: 374px) {
            padding-top: rem(120);
        }
    }
  
    &_instruction {
        padding: rem(30) 0 rem(60);
  
        @include tablet-down {
            padding: rem(20) 0 rem(40);
        }
    }
  
    &_register {
        padding: rem(30) 0;

        @include tablet-down {
            padding: rem(20) 0;
  
        }
    }
}
