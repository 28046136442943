.p-interactiveOffline {
    &_waiting {
        // background: url('~assets/images/bg_dongthuan.jpg') no-repeat;
        background-size: cover;
        padding: rem(60) rem(16);
    }

    &_interactive {
        max-width: 480px;
        width: 100%;
        background-size: cover;
        background-position: center;
        margin-left: auto;
        margin-right: auto;
        padding: rem(36) 0;
        z-index: 2;

        &_question {
            padding-left: rem(16);
            padding-right: rem(16);
        }

        &_flex {
            display: flex;
        }

        &_left,
        &_right {
            flex: 1;
            background-size: contain;
            background-repeat: no-repeat;
            cursor: pointer;
            padding-bottom: 45%;

            &_result {
                flex: 1;
                padding-bottom: 40%;
                background-size: contain;
                background-repeat: no-repeat;
            }
        }

        &_left {
            background-position: right;
            margin-right: rem(8);

            &_result {
                background-position: right;
                margin-right: rem(4);
            }
        }

        &_right {
            background-position: left;
            margin-left: rem(8);

            &_result {
                margin-left: rem(4);
            }
        }

        &_countdown {
            margin-top: rem(16);
            margin-right: rem(16);
            position: relative;
            margin-left: auto;
            height: 40px;
            width: 40px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            &-number {
                color: #cc9439;
                display: inline-block;
                font-weight: bold;
                @include font-base(18, 20);

                margin-top: rem(4);
            }

            &_circle {
                position: absolute;

                @keyframes dashed {
                    to {
                        transform: rotate(360deg);
                    }
                }

                height: 40px;
                top: 0;
                left: 0;

                &::after,
                &::before {
                    border-radius: 50%;
                    content: '';
                    height: 34px;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 34px;
                }

                &::before {
                    -webkit-animation: dashed 1.5s linear infinite;
                    animation: dashed 1.5s linear infinite;
                    border: 3px dashed #cc9439;
                    border-bottom: 3px solid transparent;
                    border-left: 3px solid transparent;
                    z-index: 5;
                }

                &::after {
                    -webkit-animation: dashed 1.5s ease infinite;
                    animation: dashed 1.5s ease infinite;
                    border: 3px solid #cc9439;
                    border-bottom: 3px solid transparent;
                    border-left: 3px solid transparent;
                    z-index: 10;
                }

            }
        }
    }

    &_logo {
        width: 100%;
        @include adjust-flex(center, center);

        @include mobile-down {
            padding: 0 rem(16);
        }

        &_item {
            width: rem(114);
            margin-left: rem(22);
            margin-right: rem(22);


            @include break-min($standard-viewport) {
                width: 114 * $w;
            }

            @include mobile-down {
                width: 100%;
                max-width: rem(74);
            }

            &:last-child {
                width: rem(106);

                @include break-min($standard-viewport) {
                    width: 106 * $w;
                }
            }
        }
    }

    &_title {
        @include mobile-down {
            margin-top: rem(40);
        }

        @media (max-width: 376px) {
            .a-text {
                @include font-base(16, 20);
            }
        }
    }

    &_sologan {
        margin-top: rem(12);

        @include mobile-down {
            margin-top: rem(8);

            .a-text {
                @include font-base(16, 24);
            }
        }

        @media (max-width: 376px) {
            .a-text {
                @include font-base(16, 20);
            }
        }
    }

    &_content {
        margin-top: rem(60);

        @include mobile-down {
            .a-text {
                @include font-base(26, 39);
            }
        }
    }

    &_bottom {
        width: calc(100% + 32px);
        margin-left: -16px;
        margin-top: rem(100);
    }
}
