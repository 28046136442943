.p-baomat {
    font-family: initial !important;
    font-size: initial !important;

    * {
        font-family: initial !important;
        font-size: initial !important;
        margin: revert;
        padding: revert;
    }
}
