.o-combos {
    $root: &;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_left {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
    }

    &_thumbnail {
        width: rem(100);
        height: rem(100);
        overflow: hidden;
        flex-shrink: 0;

        @include max-large-desktop-down {
            width: rem(80);
            height: rem(80);
        }

        @include max-desktop-down {
            width: rem(50);
            height: rem(50);
        }

        @include desktop-down {
            width: rem(40);
            height: rem(40);
        }

        @include tablet-down {
            width: rem(32);
            height: rem(32);
        }

        @include mobile-lands {
            width: rem(30);
            height: rem(30);
        }

        img {
            object-fit: contain;
        }
    }

    &-isOutOfStock {
        opacity: 0.5;
        color: $gray;

        #{$root}_title {
            color: $gray;
        }

        #{$root}_numberOrdered {
            color: $gray;
        }
    }

    &_quantity {
        display: flex;
        align-items: center;
        justify-content: center;

        &-outOfStock {
            width: rem(200);
            height: rem(55);
            border-radius: rem(10);
            border: 0.5px solid #1b9447;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $light-brilliant-red;
            font-weight: 500;
            @include font-base(28, 32);
            font-family: $font-castrol-sans-conml;

            @include max-large-desktop-down {
                width: rem(166);
                height: rem(48);
                @include font-base(24, 28);
            }

            @include max-desktop-down {
                width: rem(114);
                height: rem(36);
                @include font-base(16, 21);
            }

            @include desktop-down {
                border-radius: rem(8);
                @include font-base(14, 18);
                width: rem(98);
                height: rem(28);
            }

            @include tablet-down {
                width: rem(68);
                height: rem(22);
                border-radius: rem(6);
                @include font-base(12, 18);
            }
        }
    }

    &_title {
        font-family: $font-din-engschrift;
        @include font-base(24, 32);
        color: #009143;
        font-weight: normal;
        margin-left: rem(12);
        margin-right: rem(12);
        text-align: right;

        @include max-desktop-down {
            @include font-base(18, 21);
        }

        @include desktop-down {
            @include font-base(14, 18);
            margin-left: rem(6);
            margin-right: rem(6);
        }

        @include tablet-down {
            @include font-base(12, 16);
            margin-left: rem(4);
            margin-right: rem(4);
        }

        @include iphone-large-lands {
            margin-left: rem(3);
            margin-right: rem(3);
        }

        @include mobile-lands {
            @include font-base(11, 14);
        }

        @include iphone-small-lands {
            @include font-base(10, 13);
        }
    }

    &_numberOrdered {
        font-family: $font-din-engschrift;
        @include font-base(24, 32);
        color: $davys-grey;
        font-weight: normal;
        margin-left: rem(12);
        margin-right: rem(12);
        text-align: right;

        @include max-desktop-down {
            @include font-base(18, 21);
        }

        @include desktop-down {
            @include font-base(14, 18);
            margin-left: rem(6);
            margin-right: rem(6);
        }

        @include tablet-down {
            @include font-base(12, 16);
            margin-left: rem(4);
            margin-right: rem(4);
        }
    }

    &_input {
        width: rem(110);
        height: rem(55);
        border-radius: 0;
        border-style: solid;
        border-color: #1b9447;
        border-top-width: 0.5px;
        border-bottom-width: 0.5px;
        border-left-width: 0;
        border-right-width: 0;
        text-align: center;
        font-family: $font-din-engschrift;
        font-weight: normal;
        @include font-base(28, 32);
        color: #3d3d3d;

        @include max-large-desktop-down {
            width: rem(90);
            height: rem(48);
            @include font-base(24, 28);
        }

        @include max-desktop-down {
            width: rem(62);
            height: rem(36);
            @include font-base(18, 21);
        }

        @include desktop-down {
            @include font-base(16, 18);
            width: rem(54);
            height: rem(28);
        }

        @include tablet-down {
            @include font-base(12, 14);
            width: rem(28);
            height: rem(22);
        }
    }

    input[type=password]:not(:placeholder-shown) {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
    }

    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input:focus {
        outline: none;
    }

    &_actions {
        width: rem(45);
        height: rem(55);
        border: 0.5px solid #1b9447;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #3d3d3d;
        @include font-base(28, 32);
        font-family: $font-gmv-din-pro-cond;

        @include max-large-desktop-down {
            width: rem(38);
            height: rem(48);
            @include font-base(24, 28);
        }

        @include max-desktop-down {
            width: rem(26);
            height: rem(36);
            @include font-base(21, 24);
        }

        @include desktop-down {
            @include font-base(16, 21);
            width: rem(22);
            height: rem(28);
        }

        @include tablet-down {
            width: rem(20);
            height: rem(22);
        }

        &.increase {
            border-top-right-radius: rem(14);
            border-bottom-right-radius: rem(14);

            @include max-large-desktop-down {
                border-top-right-radius: rem(12);
                border-bottom-right-radius: rem(12);
            }

            @include max-desktop-down {
                border-top-right-radius: rem(10);
                border-bottom-right-radius: rem(10);
            }

            @include desktop-down {
                border-top-right-radius: rem(8);
                border-bottom-right-radius: rem(8);
            }

            @include tablet-down {
                border-top-right-radius: rem(6);
                border-bottom-right-radius: rem(6);
            }
        }

        &.decrease {
            border-top-left-radius: rem(14);
            border-bottom-left-radius: rem(14);

            @include max-large-desktop-down {
                border-top-left-radius: rem(12);
                border-bottom-left-radius: rem(12);
            }

            @include max-desktop-down {
                border-top-left-radius: rem(10);
                border-bottom-left-radius: rem(10);
            }

            @include desktop-down {
                border-top-left-radius: rem(8);
                border-bottom-left-radius: rem(8);
            }

            @include tablet-down {
                border-top-left-radius: rem(6);
                border-bottom-left-radius: rem(6);
            }
        }
    }

    &_description {
        text-transform: uppercase;
        font-family: $font-din-engschrift;
        @include font-base(21, 28);
        color: #009143;
        font-weight: normal;
        text-align: right;
        margin-left: rem(12);
        margin-right: rem(12);

        @include max-desktop-down {
            @include font-base(16, 18);
        }

        @include desktop-down {
            @include font-base(12, 16);
            margin-left: rem(6);
            margin-right: rem(6);
        }

        @include tablet-down {
            @include font-base(11, 14);
            margin-left: rem(4);
            margin-right: rem(4);
        }
    }

    &_bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
