.t-questions {
    position: fixed;
    width: 100%;
    top: -100%;
    left: 0;
    background-color: $black;
    transition: $transition-long ease;
    z-index: 100;

    &-active {
        top: 0;
    }

    &_content {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        width: 90%;
        height: 90%;
        background-color: $white;
        border-radius: rem(12);

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url('~assets/images/livestream/qatopdecor.png') no-repeat;
            z-index: 1;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            width: 48.5%;
            height: 41.82%;
            background: url('~assets/images/livestream/qabottomdecor.png') no-repeat;
            background-size: contain;
            background-position: right bottom;
        }
    }

    &_contentWrap {
        @include adjust-flex();
        flex-direction: column;
        padding: rem(120) rem(90);
        height: 100%;
        position: relative;
    }

    &_countdownWrap {
        width: 100%;
        max-width: rem(60);
        position: absolute;
        top: calc(120 / 688) * 100%;
        right: calc(90 / 1266) * 100%;

        &-rotate {
            img {
                animation: rotator 1.4s linear infinite;
            }
        }
    }

    &_countdownText {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -40%);
    }

    &_title {
        margin-top: rem(16);
    }

    &_buttonWrap {
        display: flex;
    }

    &_bottomContent {
        margin-top: rem(32);
    }
    
    &_buttonRight {
        margin-left: rem(20);
    }

    &_button {
        min-width: rem(180);

        .a-button {
            border-radius: 12px;
            border: 2px solid var(--dkngaystroke, #ecbc79);
            background: var(--DKNGAY, linear-gradient(87deg, #ecbc79 0.57%, #bc8b42 7.36%, #edd79d 25.89%, #fff3bf 51%, #ecd69c 75.3%, #bc8b42 88.26%, #ecbc79 98.19%));

            &_wrapper {
                background: none;
            }
        }

        span {
            @include font-base(20, 32);

            @include mobile-down {
                @include font-base(16, 24);

            }
        }

        @include mobile-down {
            max-width: rem(190);
            margin: rem(24) auto 0;
            padding-top: 0;

        }
    }
}
@keyframes rotator {
    0% {
        transform: rotate(0deg);
    }
  
    100% {
        transform: rotate(360deg);
    }
}
