html,
body {
    // font-family: $font-svn-brandon;
    font-size: 16px;
    overflow-x: hidden;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent; //issue click in mobile flash background highligh
}

.wrapper {
    overflow: hidden;
}

button {
    &:focus {
        outline: none;
    }
}

p {
    margin-bottom: 0;
    margin-top: 0;
}

a {
    text-decoration: none;
}

[role='button'],
button {
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

input {
    word-break: normal;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

.hidden-animation {
    visibility: hidden;
}

.overflow-hidden {
    height: 100vh;
}

.u-mt {
    &-24 {
        margin-top: rem(24);
    }

    &-16 {
        margin-top: rem(16);
    }
}

.tablet-hide {
    @include tablet-down {
        display: none;
    }
}

.tablet-show {
    @include tablet-up {
        display: none;
    }
}

.banner-mobile {
    position: fixed;
    flex-shrink: 0;
    width: 100%;
    height: rem(161);
    background: $white;
    z-index: 2;
    transition: all 0.3s ease-in-out;

    &::before {
        content: '';
        position: absolute;
        bottom: calc(37.5 / 161 * 100%);
        left: 50%;
        transform: translate(-50%, 0);
        width: rem(286);
        height: rem(67);
        background-image: url('~assets/images/logo_brand.svg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center center;
        z-index: 1;
        transition: all 0.26s ease-in-out;

        @include max-desktop-down {
            width: rem(200);
            height: rem(50);
        }

        @include tablet-down {
            bottom: calc(29 / 131 * 100%);
            width: rem(197);
            height: rem(46);
        }
    }

    @include max-desktop-down {
        height: rem(100);
    }

    @include tablet-down {
        height: rem(131);
    }

    @include iphone-large-lands {
        height: rem(100);
    }

    &-power1 {
        position: relative;
        height: rem(140);
        background: transparent;

        @include max-desktop-down {
            height: rem(120);
        }

        // @include tablet-down {
        //     height: rem(100);
        // }

        @include iphone-large-lands {
            height: rem(80);
        }

        &::before {
            content: '';
            position: absolute;
            bottom: calc(37.5 / 161 * 100%);
            left: 50%;
            transform: translate(-50%, 0);
            width: rem(270);
            height: rem(80);
            background-image: url('~assets/images/logo_brand_power1.svg');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center center;
            z-index: 1;
            transition: all 0.26s ease-in-out;

            @include max-desktop-down {
                width: rem(240);
                height: rem(70);
            }

            @include tablet-down {
                bottom: calc(29 / 131 * 100%);
                width: rem(200);
                height: rem(60);
            }

            @include iphone-large-lands {
                height: rem(50);
            }
        }
    }

    &-gtx {
        position: relative;
        height: rem(140);
        background: transparent;

        @include max-desktop-down {
            height: rem(120);
        }

        // @include tablet-down {
        //     height: rem(100);
        // }

        @include iphone-large-lands {
            height: rem(80);
        }

        &::before {
            content: '';
            position: absolute;
            bottom: calc(37.5 / 161 * 100%);
            left: 50%;
            transform: translate(-50%, 0);
            width: rem(270);
            height: rem(80);
            background-image: url('~assets/images/logo_castrol_gtx.svg');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center center;
            z-index: 1;
            transition: all 0.26s ease-in-out;

            @include max-desktop-down {
                width: rem(240);
                height: rem(70);
            }

            @include tablet-down {
                bottom: calc(29 / 131 * 100%);
                width: rem(220);
                height: rem(70);
            }

            @include iphone-large-lands {
                height: rem(50);
            }
        }
    }

    &-transparent {
        background: transparent;
    }

    &-shadow {
        height: rem(131);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.05) 2%, rgba(255, 255, 255, 0.04) 40%, rgba(255, 255, 255, 0.03) 68%, rgba(255, 255, 255, 0.08) 80%, rgba(255, 255, 255, 0.05) 90%, rgba(255, 255, 255, 0.02) 100%);
        // background: transparent;

        @include mobile-down {
            background: linear-gradient(180deg, #86898c 0%, #989a9d 2%, #bdbec0 10%, #dadbdc 25%, #eeefef 40%, rgba(#fbfbfb, 0.8) 68%, rgba(255, 255, 255, 0.7) 80%, rgba(255, 255, 255, 0.5) 90%, rgba(255, 255, 255, 0.05) 100%);
        }

        @include iphone-large-lands {
            background: linear-gradient(180deg, #86898c 0%, #989a9d 2%, #bdbec0 10%, #dadbdc 25%, #eeefef 40%, rgba(#fbfbfb, 0.8) 68%, rgba(255, 255, 255, 0.7) 80%, rgba(255, 255, 255, 0.5) 90%, rgba(255, 255, 255, 0.05) 100%);
        }

        &::before {
            bottom: calc(29 / 131 * 100%);
            width: rem(197);
            height: rem(46);
        }
    }

}

.text-error {
    color: #ea1c27;
    @include font-base(18, 21);

    @include max-desktop-down {
        @include font-base(16, 19);
    }

    @include mobile-down {
        @include font-base(14, 18);
    }

    &-info {
        color: #606060;
        text-align: center;
        margin-top: rem(10);
    }
}

.text-error-inCart {
    color: #f00;
    font-family: $font-gmv-din-pro-cond;
    font-weight: 700;
    @include font-base(28, 34);
    text-align: center;
    margin-top: rem(10);

    @include max-large-desktop-down {
        @include font-base(24, 28);
    }

    @include max-desktop-down {
        @include font-base(21, 28);
    }

    @include desktop-down {
        @include font-base(18, 21);
    }

    @include mobile-down {
        @include font-base(14, 16);
    }

    @include iphone-large-lands {
        @include font-base(14, 16);
    }

    @include mobile-lands {
        @include font-base(13, 15);
    }

    @include iphone-medium-lands {
        @include font-base(12, 15);
    }

    &_small {
        @include max-large-desktop-down {
            @include font-base(23, 28);
        }

        @include max-desktop-down {
            @include font-base(18, 24);
        }

        @include desktop-down {
            @include font-base(14, 21);
        }

        @include mobile-down {
            @include font-base(13, 16);
        }

        @include iphone-large-lands {
            @include font-base(12, 15);
        }

        @include mobile-lands {
            @include font-base(11, 14);
        }

        @include iphone-medium-lands {
            @include font-base(9, 13);
        }
    }
}

.iconWarning {
    width: rem(60);
    height: rem(60);
    background: #fff;
    border: 1.7px solid #009343;
    border-radius: 50%;
    padding: rem(10) rem(2);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 1;

    @include max-desktop-down {
        width: rem(50);
        height: rem(50);
    }

    @include desktop-down {
        width: rem(40);
        height: rem(40);
    }

    @include iphone-large-lands {
        width: rem(32);
        height: rem(32);
        padding: rem(4) rem(2);
    }
}

.eventOnline {
    position: relative;
    background: url('~assets/images/register/event_online.svg') no-repeat center center;
    background-size: contain;
    margin: 0 auto;
    flex-shrink: 0;
    width: rem(243);
    height: rem(83);

    @include max-large-desktop-down {
        width: rem(180);
        height: rem(70);
    }

    @include max-desktop-down {
        width: rem(161);
        height: rem(55);
    }
}

.eventOnlinePhase3 {
    position: relative;
    background: url('~assets/images/register/event_online_phase3.svg') no-repeat center center;
    background-size: contain;
    margin: 0 auto;
    flex-shrink: 0;
    width: rem(440);
    height: rem(98);

    // @include max-large-desktop-down {
    //     width: rem(180);
    //     height: rem(70);
    // }

    @include max-desktop-down {
        width: rem(246);
        height: rem(59);
    }

    @include desktop-down {
        width: rem(220);
        height: rem(52);
    }
}

.textCommingProduct {
    color: #008d3f;
    text-align: center;
    @include font-base(48, 52);
    font-family: $font-montserrat-black;

    @include max-large-desktop-down {
        @include font-base(42, 48);
    }

    @include max-desktop-down {
        @include font-base(32, 44);
    }

    @include tablet-down {
        @include font-base(26, 31);
    }
}

.speedWithCastrol {
    position: relative;
    background: url('~assets/images/register/speed_castrol.svg') no-repeat center center;
    background-size: contain;
    width: 100%;
    margin: rem(10) auto;
    flex-shrink: 0;

    max-width: rem(540);
    height: rem(172);

    @include max-large-desktop-down {
        max-width: rem(390);
        height: rem(130);
    }

    @include max-desktop-down {
        max-width: rem(358);
        height: rem(114);
    }
}

.donduxuanWithCastrol {
    position: relative;
    background: url('~assets/images/donxuhuong.svg') no-repeat center center;
    background-size: contain;
    width: 100%;
    margin: rem(10) auto;
    flex-shrink: 0;
    font-family: $font-gmv-din-pro-cond;

    max-width: rem(540);
    height: rem(172);

    @include max-large-desktop-down {
        max-width: rem(390);
        height: rem(130);
    }

    @include max-desktop-down {
        max-width: rem(358);
        height: rem(114);
    }
}

.luachonso1 {
    position: relative;
    background: url('~assets/images/lua_chon_so_so1.svg') no-repeat center center;
    background-size: contain;
    width: 100%;
    margin: rem(10) auto;
    flex-shrink: 0;
    font-family: $font-gmv-din-pro-cond;

    max-width: rem(840);
    height: rem(200);

    @include max-large-desktop-down {
        max-width: rem(650);
        height: rem(150);
    }

    @include max-desktop-down {
        max-width: rem(600);
        height: rem(120);
    }

    @include desktop-down {
        max-width: rem(470);
    }
}

.ready_phase3 {
    position: relative;
    background: url('~assets/images/register/ready_phase3.svg') no-repeat center center;
    background-size: contain;
    width: 100%;
    margin: 0 auto rem(10);
    flex-shrink: 0;
    font-family: $font-gmv-din-pro-cond;
    max-width: rem(800);
    height: rem(122);

    @include max-large-desktop-down {
        max-width: rem(500);
        height: rem(100);
    }

    @include max-desktop-down {
        max-width: rem(460);
        height: rem(90);
    }

    @include desktop-down {
        max-width: rem(320);
        height: rem(80);
    }
}

.dot-flashing {
    position: relative;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: $white;
    color: $white;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot-flashing::before {
    left: -10px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: $white;
    color: $white;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
}

.dot-flashing::after {
    left: 10px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: $white;
    color: $white;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
}

@keyframes dot-flashing {
    0% {
        background-color: $white;
    }

    50%,
    100% {
        background-color: rgba(152, 128, 255, 0.2);
    }
}

.list-tnc {
    list-style: none;

    li {
        position: relative;

        &::before {
            content: '';
            height: 1px;
            width: 8px;
            background-color: #666363;
            position: absolute;
            left: -14px;
            top: 12px;

            @include mobile-down {
                top: 8px;
            }
        }
    }

    &-2 {
        list-style-type: disc;

        li {
            &::before {
                content: unset;
            }
        }
    }
}

.inApp {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        font-size: rem(21);
        line-height: rem(32);
        text-align: center;

        a {
            text-transform: uppercase;
        }
    }
}

.spacing {

    @include mobile-down {
        height: rem(140);
    }
}
