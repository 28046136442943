.t-agenda {
    position: relative;

    &_list {
        background: linear-gradient($white, $white) padding-box, linear-gradient(to right, $peru, $mellow-apricot) border-box;
        border: 2px solid transparent;
        border-radius: rem(20);
        padding: rem(2.16);
        position: relative;

        &_wrap {
            display: flex;
            flex-wrap: wrap;
            border-radius: rem(32);
            padding: rem(70) rem(24) rem(24);

            @include mobile-down {
                padding: rem(40) rem(16) rem(26);
            }
        }

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            width: 48.5%;
            height: 41.82%;
            background: url('~assets/images/testimonials/decorate.png') no-repeat;
            background-size: contain;
            background-position: right bottom;
        }
    }

    &_listItem {
        flex: 0 0 50%;
        max-width: 50%;

        @include tablet-down {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    &_left {
        padding-right: rem(24);
        position: relative;

        &::after {
            background: $linear-orange-devider;
            content: '';
            position: absolute;
            top: 10%;
            right: 0;
            height: 80%;
            width: 1px;

            @include tablet-down {
                width: 0;
            }
        }

        @include tablet-down {
            padding-right: 0;
        }
    }

    &_right {
        padding-left: rem(24);

        @include tablet-down {
            padding-left: 0;
            margin-top: rem(28);
        }
    }

    &_item {
        display: flex;

        &:not(:first-child) {
            margin-top: rem(36);

            @include mobile-down {
                margin-top: rem(28);
            }
        }

        @include mobile-down {
            flex-wrap: wrap;
            max-width: rem(305);
        }

        &_datetime {
            flex: 0 0 rem(110);
            max-width: rem(110);

            @include mobile-down {
                flex: 0 0 100%;
                max-width: 100%;
            }

            .a-text-24x32 {
                @include mobile-down {
                    @include font-base(14, 20); }
            }
        }

        &_content {
            margin-left: rem(26);
            width: 100%;

            @include tablet-down {
                margin-left: rem(14);
            }

            @include mobile-down {
                margin-left: 0;
                margin-top: rem(4);
            }
        }

        &_title {
            position: relative;

            &::before {
                background: linear-gradient(90deg, rgba(203, 152, 49, 0) 0%, rgba(242, 217, 141, 0.8753) 23.47%, #f8e29a 26.81%, #e0ae31 59.71%, #eed88c 79.76%, #f3e8af 87.07%, rgba(224, 174, 49, 0) 99.98%);
                bottom: 0;
                content: '';
                height: 2px;
                left: 0;
                position: absolute;
                width: rem(253);

                @include mobile-down {
                    left: 35%;
                    transform: translateX(-50%);
                    width: rem(240);
                }
            }

            @include mobile-down {
                .a-text {
                    @include font-base(16, 32); }
            }
        }

        &_subTitle {
            .a-text {
                @include text-background($california-gold); }
        }

        &_speaker {
            margin-top: rem(16);

            .a-text {
                @include mobile-down {
                    @include font-base(16, 32);}
            }
        }
    }


    &_heading {
        left: 50%;
        position: absolute;
        text-align: center;
        top: rem(-32);
        transform: translateX(-50%);
        z-index: $z-1;
        // width: fit-content;
        max-width: rem(656);
        width: 100%;
        background: var(--dkngaystroke, $mellow-apricot);
        padding: rem(2);
        border-radius: rem(78);

        @include tablet-down {
            max-width: rem(420);
        }
    
        @include mobile-down {
            max-width: rem(320);
            top: rem(-22);
        }

        &_wrap {
            border-radius: inherit;
            padding: 0 rem(45);
            height: rem(56);
            position: relative;
            @include adjust-flex();

            .a-heading {
                position: relative;
                transform: translateY(6px);

                @include mobile-down {
                    transform: translateY(2px);
                    @include font-base(20, 34); 
                }
            }

            @include mobile-down {
                padding: 0 rem(14);
                height: rem(36);

            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: inherit;
                background: $linear-orange-title;
            }
        }
    }
}
