.o-popupInCart {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    padding: rem(24);

    @include iphone-large-lands {
        padding: rem(16) rem(8);
    }

    &-show {
        opacity: 1;
        visibility: visible;
    }

    &-dontHightlight {
        .t-livestream_sidebar_inside {
            &::after {
                display: none;
            }
        }
    }

    &_content {
        width: 100%;
        height: 80%;
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        overflow: hidden auto;

        @include iphone-large-lands {
            height: 88%;
        }

        &-notify {
            color: #1b9447;
            font-weight: 700;
            font-size: rem(36);
            text-align: center;
            font-family: $font-gmv-din-pro-cond-black;

            @include max-desktop-down {
                font-size: rem(28);
            }

            @include desktop-down {
                font-size: rem(24);
            }

            @include tablet-down {
                font-size: rem(21);
            }

            @include iphone-large-lands {
                font-size: rem(18);
            }

            @include iphone-medium-lands {
                font-size: rem(16);
            }
        }
    }

    .iconWarning {
        margin-top: rem(8);
    }
}
