.p-eventWaiting {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    video {
        display: block;
        width: 100%;
        height: 100%;
        max-width: 100%;
    }

    img {
        width: 100%;
        height: 100%;

        @include mobile-down {
            height: auto;
        }
    }

    &_video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        visibility: hidden;
        opacity: 0;
        z-index: -1;

        &-show {
            visibility: visible;
            opacity: 1;
            z-index: 1;
            background-color: $white;
        }

        &-hide {
            display: none !important;
        }
    }

    &_image {
        position: absolute;
        width: 100%;
        visibility: hidden;
        opacity: 0;
        z-index: -1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &-show {
            visibility: visible;
            opacity: 1;
            z-index: 1;
            background-color: $white;
        }

        &-hide {
            display: none !important;
        }
    }
}
