.t-voucher {
    &_nothing {
        width: 100%;
        height: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &_content {
            font-family: $font-gmv-din-pro-cond;
            font-weight: 700;
            @include font-base(32, 39);
            text-align: center;
            color: #606060;
            margin-top: rem(20);

            @include max-desktop-down {
                @include font-base(28, 34);
            }

            @include desktop-down {
                @include font-base(21, 28);
            }

            @include tablet-down {
                @include font-base(18, 21);
            }
        }
    }

    &_reviecedGift {
        font-family: $font-gmv-din-pro-cond;
        font-weight: 700;
        @include font-base(22, 28);
        text-align: center;
        color: #ea1c27;
        margin-top: rem(8);

        @include max-large-desktop-down {
            @include font-base(19, 21);
        }

        @include max-desktop-down {
            @include font-base(16, 18);
        }

        @include desktop-down {
            @include font-base(14, 18);
        }

        @include tablet-down {
            @include font-base(12, 14);
        }
    }

    &_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: rem(16);
        margin-bottom: rem(32);

        @include max-large-desktop-down {
            margin-top: rem(14);
            margin-bottom: rem(24);
        }

        @include max-desktop-down {
            margin-top: rem(12);
            margin-bottom: rem(20);
        }

        @include tablet-down {
            margin-bottom: rem(12);
        }

        &_button {
            margin-left: rem(6);

            button {
                min-width: rem(90);
                padding-left: rem(4);
                padding-right: rem(4);

                @include max-large-desktop-down {
                    @include font-base(18, 20);
                    min-width: rem(72);
                }

                @include desktop-down {
                    @include font-base(12, 14);
                    min-width: rem(50);
                }
            }

            &-isPhase3 {
                margin-left: rem(3);

                button {
                    @include mobile-down {
                        @include font-base(9, 12);
                        min-width: rem(40);
                    }
                }
            }
        }
    }

    &_exchange {
        position: relative;
        z-index: 2;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;

        &-line1 {
            font-family: $font-din-engschrift;
            font-weight: normal;
            @include font-base(45, 54);
            text-align: center;
            color: #1b9447;

            @include max-desktop-down {
                @include font-base(28, 34);
            }

            @include desktop-down {
                @include font-base(21, 28);
            }

            @include tablet-down {
                @include font-base(16, 18);
            }
        }

        &-line2 {
            font-family: $font-din-engschrift;
            font-weight: normal;
            @include font-base(30, 32);
            text-align: center;
            color: #1b9447;
            margin-top: rem(10);

            @include max-large-desktop-down {
                @include font-base(24, 32);
            }

            @include max-desktop-down {
                @include font-base(21, 28);
                margin-top: rem(4);
            }

            @include desktop-down {
                @include font-base(18, 21);
            }

            @include tablet-down {
                @include font-base(14, 18);
            }
        }

        &-line3 {
            font-family: $font-gmv-din-pro-cond;
            font-weight: normal;
            @include font-base(39, 47);
            text-align: center;
            color: #1b9447;
            margin-top: rem(30);

            @include max-large-desktop-down {
                @include font-base(32, 38);
                margin-top: rem(20);
            }

            @include max-desktop-down {
                @include font-base(28, 32);
            }

            @include desktop-down {
                @include font-base(21, 28);
                margin-top: rem(12);
            }

            @include tablet-down {
                @include font-base(16, 18);
                margin-top: rem(10);
            }
        }

        &-line4 {
            font-family: $font-gmv-din-pro-cond;
            font-weight: normal;
            color: #606060;
            @include font-base(25, 31);
            text-align: center;
            margin-top: rem(40);
            margin-bottom: rem(10);

            @include max-large-desktop-down {
                @include font-base(24, 32);
                margin-top: rem(30);
            }

            @include max-desktop-down {
                @include font-base(18, 21);
                margin-top: rem(20);
            }

            @include desktop-down {
                @include font-base(16, 18);
                margin-top: rem(20);
            }

            @include tablet-down {
                @include font-base(13, 16);
                margin-top: rem(10);
            }
        }

        &-hotline {
            max-width: rem(326);
            width: 100%;
            margin-left: auto;
            margin-right: auto;

            svg {
                width: 100%;
                height: 100%;
            }

            @include max-desktop-down {
                max-width: rem(200);
            }
        }
    }
}
