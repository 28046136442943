.p-login {
    &_step3 {
        .t-background_content {
            background-image: url('~assets/images/register/background_desktop_2.png');

            @include tablet-down {
                background-image: url('~assets/images/register/background_2.png');
            }
        }

        .eventOnline {
            margin-top: 1%;
            // width: rem(200);
            // height: rem(70);

            // @include max-large-desktop-down {
            //     width: rem(150);
            //     height: rem(60);
            // }

            // @include max-desktop-down {
            //     width: rem(100);
            //     height: rem(32);
            // }
        }

        .speedWithCastrol {
            position: relative;
            background: url('~assets/images/register/speed_castrol.svg') no-repeat center center;
            background-size: contain;
            width: 100%;
            margin: rem(10) auto;
            flex-shrink: 0;

            // max-width: rem(480);
            // height: rem(160);

            // @include max-large-desktop-down {
            //     max-width: rem(300);
            //     height: rem(100);
            // }

            // @include max-desktop-down {
            //     max-width: rem(200);
            //     height: rem(68);
            // }
        }

        &_info {
            font-family: $font-din-engschrift;
            font-weight: normal;
            @include font-base(53, 64);
            text-align: center;
            color: $forest-green;
            margin-top: rem(44);

            @include max-large-desktop-down {
                @include font-base(42, 58);
                margin-top: rem(30);
            }

            @include max-desktop-down {
                @include font-base(32, 46);
                margin-top: rem(16);
            }

            @include tablet-down {
                @include font-base(26, 31);
                margin-top: rem(31);
            }
        }

        &_otpExpired {
            font-family: $font-din-engschrift;
            font-weight: normal;
            text-align: center;
            color: $forest-green;
            margin-top: rem(120);
            @include font-base(53, 63);

            @include max-desktop-down {
                @include font-base(32, 46);
                margin-top: rem(60);
            }

            @include tablet-down {
                @include font-base(26, 31);
            }

            @include mobile-down {
                margin-top: 16%;
            }
        }

        &_timeExpired {
            font-family: $font-din-engschrift;
            font-weight: normal;
            text-align: center;
            color: $forest-green;
            @include font-base(53, 64);

            @include max-large-desktop-down {
                @include font-base(40, 54);
            }

            @include max-desktop-down {
                @include font-base(32, 46);
            }

            @include tablet-down {
                @include font-base(26, 31);
            }

            &-duration {
                @include font-base(65, 82);
                font-weight: bold;

                @include max-large-desktop-down {
                    @include font-base(52, 68);
                }

                @include max-desktop-down {
                    @include font-base(40, 52);
                }

                @include tablet-down {
                    @include font-base(30, 38);
                }
            }
        }
    }
}
