.o-popupInfo {
    &_popup {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        z-index: 2;
        position: relative;
        height: 100%;
        padding-bottom: rem(10);

        @include tablet-down {
            padding-bottom: rem(10);
        }

        &-title,
        &-description {
            font-family: $font-din-engschrift;
            font-weight: normal;
            @include font-base(39, 47);
            text-align: center;
            color: #ea1c27;

            @include max-desktop-down {
                @include font-base(28, 37);
            }

            @include mobile-down {
                @include font-base(23, 32);
            }

            @include x-small-mobile {
                @include font-base(18, 28);
            }

            @include iphone-large-lands {
                @include font-base(18, 28);
            }

            &-registered {
                color: $spanish-green;

                a {
                    color: #ea1c27;
                }
            }
        }

        &-description {
            margin-top: rem(24);

            @include tablet-down {
                margin-top: rem(16);
            }

            &-registered {
                color: #ea1c27;
            }
        }

        &_content {
            flex: 1;
            padding: rem(22) rem(12) 0;
            // min-height: 63vh;
            // background: transparent url('~assets/images/background-popup.png') no-repeat;
            // background-size: cover;
            // background-position: center 89%;

            // @include mobile-down {
            //     min-height: 50vh;
            //     padding-top: rem(30);
            //     background-position: center 90%;
            // }

            // @include iphone-large-lands {
            //     min-height: 50vh;
            //     padding-top: rem(20);
            //     padding-bottom: rem(20);
            //     background-position: center 48%;
            // }
        }

        &_bottom {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            background-image: url('~assets/images/hotline.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            width: rem(400);
            height: rem(130);
            padding-right: rem(12);
            margin-left: auto;
            margin-right: auto;
            flex: 0 0 auto;

            @include max-large-desktop-down {
                width: rem(326);
                height: rem(100);

            }

            @include max-desktop-down {
                width: rem(250);
                height: rem(80);
            }

            @include iphone-large-lands {
                width: rem(200);
                height: rem(65);
            }

            a {
                color: $north-texas-green;
                font-family: $font-montserrat;
                font-weight: 700;
                font-size: rem(38);
                padding-right: rem(14);

                @include max-large-desktop-down {
                    padding-right: rem(14);
                    font-size: rem(30);
                }

                @include max-desktop-down {
                    font-size: rem(21);
                    padding-right: rem(16);
                }

                @include iphone-large-lands {
                    font-size: rem(16);
                }
            }
        }
    }
}
