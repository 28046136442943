$width-left-side: 68vw;

@mixin width-left-side {
    width: 68vw;

    @include desktop-down {
        width: 72vw;
    }
}

@mixin disconected {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    background-color: rgb(255 255 255 / 80%);
    z-index: $z-disconected;

    &_title {
        @include font-base(32, 36);
        font-family: $font-gmv-din-pro-cond;
        margin-bottom: rem(20);
        color: #ea1c27;
        font-weight: 700;

        @include tablet-down {
            @include font-base(24, 28);
        }
    }

    &_description {
        @include font-base(24, 28);
        font-family: $font-gmv-din-pro-cond;
        color: #ea1c27;
        font-weight: 700;

        @include tablet-down {
            @include font-base(21, 22);
        }
    }
}

.t-livestream {
    $root: &;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100vw;
    display: flex;
    align-items: center;
    background-color: #000;

    &_loading {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &_disconnected {
        @include disconected;

        @include ipad-large-portrait {
            display: none;
        }
    }

    &_fetchingWrap {
        position: relative;
        // margin-bottom: 10%;
        width: 100%;
        max-height: 100%;
    }

    &_fetching {
        position: absolute;
        width: max-content;
        top: 16%;
        left: 50%;
        background-color: rgba(0, 0, 0, 0.7);
        color: $white;
        border-radius: rem(8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 6;
        padding: rem(4) rem(20) rem(4) rem(12);
        font-family: $font-gmv-din-pro-cond;
        @include font-base(18, 21);
        transform: translateX(-50%);

        @include tablet-down {
            padding: rem(4) rem(16) rem(4) rem(8);
            @include font-base(12, 14);
        }

        &-text {
            margin-right: rem(20);
        }
    }

    &_video {
        width: 100%;
        height: calc(100% - 160px);
        flex: 2;
        overflow: hidden;
        position: relative;
        transition: $transition-long ease;
        z-index: 2;

        @include tablet-down {
            height: calc(100% - 60px);
        }

        @include iphone-large-lands {
            height: calc(100% - 55px);
        }

        @include mobile-lands {
            height: calc(100% - 40px);
        }

        @include iphone-medium-lands {
            height: calc(100% - 30px);
        }
    }

    &_vouchers {
        position: absolute;
        top: 36%;
        transform: translate(0, -36%);
        right: 4%;
        z-index: 5;
        width: calc(540 / 1272 * 100%);
        overflow: auto;
        height: 60%;
        @include scroll-bars(0, transparent);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        @include desktop-down {
            width: calc(340 / 772 * 100%);
            right: 5%;
        }

        @include tablet-down {
            top: 38%;
            width: calc(300 / 614 * 100%);
        }

        @include iphone-large-lands {
            top: 35%;
        }

        @include mobile-lands {
            width: calc(330 / 614 * 100%);
            height: 70%;
        }

        &_item {
            width: 100%;
            margin-top: rem(12);
            margin-bottom: rem(12);

            @include iphone-large-lands {
                margin-top: rem(4);
                margin-bottom: rem(12);
            }

            @include mobile-lands {
                margin-top: 0;
                margin-bottom: rem(12);
            }
        }
    }

    &_notification {
        position: relative;
        color: #009343;
        font-size: rem(28);
        background-color: $white;
        padding: rem(10) rem(20) rem(10) rem(32);
        border-radius: rem(20);
        border: 2px solid $red;
        max-width: 80%;
        font-weight: 600;
        font-family: $font-gmv-din-pro-cond;
        text-align: center;
        margin-bottom: rem(20);
        transition: all 0.2s ease;

        @include max-large-desktop-down {
            font-size: rem(24);
        }

        @include max-desktop-down {
            font-size: rem(18);
        }

        @include desktop-down {
            font-size: rem(16);
            padding: rem(8) rem(16) rem(8) rem(20);
            border-width: rem(1.5);
            border-radius: rem(16);
        }

        @include iphone-large-lands {
            font-size: rem(14);
            max-width: 90%;
        }

        @include mobile-lands {
            font-size: rem(13);
            padding: rem(6) rem(8) rem(6) rem(14);
            border-radius: rem(8);
            margin-bottom: rem(2);
            max-width: 95%;
        }

        @include iphone-small-lands {
            font-size: rem(12);
            padding: rem(6) rem(6) rem(4) rem(12);
            border-radius: rem(6);
        }

        &_ring {
            position: absolute;
            top: rem(-50);
            left: rem(-50);
            width: rem(90);
            height: rem(90);
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $white;
            border-radius: 50%;
            border: 2px solid $red;

            @include max-large-desktop-down {
                top: rem(-25);
                left: rem(-25);
                width: rem(60);
                height: rem(60);
            }

            @include max-desktop-down {
                top: rem(-20);
                left: rem(-20);
                width: rem(40);
                height: rem(40);
                border-width: rem(1.5);
            }

            @include desktop-down {
                top: rem(-15);
                left: rem(-15);
                width: rem(30);
                height: rem(30);
            }

            .a-icon-ring {
                width: rem(40);
                height: rem(40);

                @include max-large-desktop-down {
                    width: rem(28);
                    height: rem(28);
                }

                @include max-desktop-down {
                    width: rem(20);
                    height: rem(20);
                }

                @include desktop-down {
                    width: rem(16);
                    height: rem(16);
                }
            }
        }
    }

    &.fullscreen {
        #{$root}_video {
            height: 100%;
            flex: 1;
            width: 100vw;
        }

        #{$root}_vouchers {
            width: calc(540 / 1920 * 100%);

            @include max-large-desktop-down {
                width: calc(580 / 1920 * 100%);
            }

            @include desktop-down {
                width: calc(330 / 1180 * 100%);
            }

            @include tablet-down {
                width: calc(280 / 932 * 100%);
            }

            @include iphone-medium-lands {
                width: calc(400 / 932 * 100%);
            }
        }

        #{$root}_right-isOrder24h,
        #{$root}_right-replay {
            .t-livestream_cart {
                margin-right: 20%;
            }
        }

        #{$root}_notification {
            font-size: rem(32);
            padding: rem(10) rem(20) rem(10) rem(32);
            max-width: 70%;

            @include max-large-desktop-down {
                font-size: rem(28);
            }

            @include max-desktop-down {
                font-size: rem(24);
            }

            @include desktop-down {
                font-size: rem(18);
                padding: rem(8) rem(16) rem(8) rem(20);
                border-width: rem(1.5);
                border-radius: rem(16);
            }

            @include iphone-medium-lands {
                font-size: rem(16);
                padding: rem(6) rem(12) rem(6) rem(16);
                border-radius: rem(12);
                margin-bottom: rem(8);
            }

            @include iphone-small-lands {
                font-size: rem(14);
                border-radius: rem(8);
                margin-bottom: rem(4);
            }

            &_ring {
                position: absolute;
                top: rem(-60);
                left: rem(-60);
                width: rem(110);
                height: rem(110);
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $white;
                border-radius: 50%;
                border: 2px solid $red;

                @include max-large-desktop-down {
                    top: rem(-50);
                    left: rem(-50);
                    width: rem(90);
                    height: rem(90);
                }

                @include max-desktop-down {
                    top: rem(-25);
                    left: rem(-25);
                    width: rem(60);
                    height: rem(60);
                }

                @include desktop-down {
                    top: rem(-20);
                    left: rem(-20);
                    width: rem(40);
                    height: rem(40);
                    border-width: rem(1.5);
                }

                .a-icon-ring {
                    width: rem(60);
                    height: rem(60);

                    @include max-large-desktop-down {
                        width: rem(45);
                        height: rem(45);
                    }

                    @include max-desktop-down {
                        width: rem(28);
                        height: rem(28);
                    }

                    @include desktop-down {
                        width: rem(20);
                        height: rem(20);
                    }
                }
            }
        }
    }

    &_sidebar {
        position: relative;
        display: flex;
        align-items: flex-end;
        transition: all 0.3s ease;
        visibility: visible;
        opacity: 1;
        scale: 1;
        width: 100%;
        flex: 1.1;
        height: calc(100% - 160px);
        margin-left: rem(12);
        margin-right: rem(30);

        @include tablet-down {
            margin-left: rem(8);
            margin-right: rem(12);
            height: calc(100% - 60px);
        }

        @include iphone-large-lands {
            height: calc(100% - 55px);
        }

        @include mobile-lands {
            flex: 1.2;
            height: calc(100% - 40px);
        }

        @include iphone-medium-lands {
            height: calc(100% - 30px);
        }

        &-fullscreen {
            width: 0;
            margin-left: 0;
            margin-right: 0;
            visibility: hidden;
            opacity: 0;
            scale: 0;
            flex: 0;
        }

        &_inside {
            position: relative;
            width: 100%;
            height: 100%;
            border-radius: rem(20);
            background: linear-gradient(180deg, #fff 0%, #fff 15.78%, #fff 75.79%, #a4a4a4 100%);
            padding: rem(32);
            border: 2px solid #707070;

            @include max-large-desktop-down {
                padding: rem(20);
                border-radius: rem(16);
            }

            @include tablet-down {
                padding: rem(20) rem(12);
                border-radius: rem(12);
                background: linear-gradient(180deg, #fff 0%, #fff 15.78%, #fff 75.79%, #a4a4a4 100%);
            }

            &::after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-image: url('~assets/images/background_sidebar.png');
                background-size: 100% 70%;
                background-position: center bottom;
                background-repeat: no-repeat;
                border-radius: 20px;

                @include desktop-down {
                    background-size: 100% 50%;
                }

                @include iphone-large-lands {
                    background-size: 100% 55%;
                }
            }

            &_close {
                cursor: pointer;
                position: absolute;
                right: rem(-18);
                top: rem(-20);
                z-index: 3;
                width: rem(50);
                height: rem(50);
                background-color: #f00;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                .a-icon {
                    width: rem(24);
                    height: rem(24);
                }

                @include max-desktop-down {
                    right: rem(-21);
                    top: rem(-21);
                    width: rem(42);
                    height: rem(42);

                    .a-icon {
                        width: rem(24);
                        height: rem(24);
                    }
                }

                @include tablet-down {
                    right: rem(-11);
                    top: rem(-11);
                    width: rem(30);
                    height: rem(30);

                    .a-icon {
                        width: rem(12);
                        height: rem(12);
                    }
                }
            }

            &-combo {
                background: $white;
                padding: 0;

                &::after {
                    display: none;
                }
            }

            &-voucher {
                background: $white;
                padding: 0;
            }
        }

        &_voucher {
            position: relative;
            height: 100%;
            padding: rem(24);
            z-index: 2;

            @include iphone-large-lands {
                padding: rem(12);
            }

            @include iphone-medium-lands {
                padding: rem(8);
            }

            &_wrap {
                height: 75%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            &_title {
                font-family: $font-din-engschrift;
                font-weight: normal;
                @include font-base(43, 51);
                text-align: left;
                color: #1b9447;
                margin: 0;
                margin-bottom: rem(12);

                @include max-desktop-down {
                    @include font-base(30, 42);
                }

                @include desktop-down {
                    @include font-base(24, 32);
                }

                @include tablet-down {
                    @include font-base(18, 21);
                }
            }

            &_list {
                max-height: calc(480 / 729 * 100%);
                overflow: hidden auto;

                @include max-large-desktop-down {
                    max-height: calc(430 / 729 * 100%);
                }

                @include desktop-down {
                    max-height: calc(250 / 354 * 100%);
                }

                @include tablet-down {
                    max-height: calc(220 / 354 * 100%);
                }

                @include iphone-large-lands {
                    max-height: calc(250 / 310 * 100%);
                }
            }

            &_button {
                width: 40%;
                margin: rem(40) auto 0;

                @include max-desktop-down {
                    margin-top: rem(20);
                }

                @include tablet-down {
                    margin-top: rem(10);
                }
            }
        }
    }

    &_fullscreen {
        align-items: center;
        bottom: rem(12);
        cursor: pointer;
        display: flex;
        height: rem(36);
        justify-content: center;
        position: absolute;
        right: rem(24);
        width: rem(36);
        z-index: 5;
        background: rgba($black, 0.5);
        border-radius: rem(8);
    }

    &_sound {
        align-items: center;
        top: rem(10);
        cursor: pointer;
        display: flex;
        width: rem(50);
        height: rem(50);
        justify-content: center;
        left: rem(24);
        position: absolute;
        z-index: 5;
        background: rgba($black, 0.5);
        border: 1.58px solid #fff;
        border-radius: rem(8);
        transition: all 0.3s linear;

        @include tablet-down {
            width: rem(36);
            height: rem(36);
        }

        &-isOrder24h,
        &-replay {
            @include tablet-down {
                top: rem(20);
            }

            @include iphone-large-lands {
                top: 3%;
            }
        }

        .a-icon {
            height: rem(30);
            width: rem(30);

            @include tablet-down {
                width: rem(24);
                height: rem(24);
            }

            &-mute {
                animation: heartBeat 2s infinite;
            }
        }

        .m-volumeControl {
            min-width: unset;

            &-muted {
                .a-icon {
                    height: 30px !important;
                    width: 30px !important;


                    @include tablet-down {
                        width: rem(16) !important;
                        height: rem(16) !important;
                    }
                }
            }

            .a-icon {
                height: 24px;
                width: 24px;

                @include tablet-down {
                    width: rem(16);
                    height: rem(16);
                }
            }
        }

        &-centerScreen {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: rem(150);
            height: rem(150);
            background: rgba($black, 0.6);

            @include max-desktop-down {
                width: rem(100);
                height: rem(100);
            }

            @include tablet-down {
                width: rem(80);
                height: rem(80);
            }

            .a-icon {
                height: rem(100);
                width: rem(100);

                @include max-desktop-down {
                    width: rem(70);
                    height: rem(70);
                }

                @include tablet-down {
                    width: rem(50);
                    height: rem(50);
                }
            }
        }
    }

    &_top {
        position: absolute;
        top: rem(12);
        right: rem(12);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 7;

        &-isOrder24h,
        &-replay {
            @include tablet-down {
                top: rem(24);
            }

            @include iphone-large-lands {
                top: 5%;
            }
        }
    }

    &_logout {
        cursor: pointer;
    }

    &_logout,
    &_view,
    &_order {
        color: $white;
        font-family: $font-castrol-sans-conml;
        font-weight: normal;
        @include font-base(16, 21);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba($black, 0.5);
        padding: rem(4) rem(8);
        z-index: 5;
        border: 1.58px solid #fff;
        opacity: 0.65;
        border-radius: rem(8);

        &-number {
            margin-left: rem(3);
            color: $white;
            font-family: $font-castrol-sans-conml;

            @include tablet-down {
                @include font-base(12, 16);
            }
        }

        .a-icon {
            height: rem(24);
            width: rem(24);

            @include tablet-down {
                width: rem(16);
                height: rem(16);
            }
        }
    }

    &_playButton {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        z-index: 5;
    }

    &_action {
        &_button {
            cursor: pointer;
            width: rem(41);
            height: rem(43);
            background-image: url('~assets/icons/ic_send.svg');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            position: absolute;
            right: rem(20);
            top: 49%;
            transform: translate(0, -50%);

            @include max-large-desktop-down {
                width: rem(32);
                height: rem(34);
            }

            @include max-desktop-down {
                width: rem(24);
                height: rem(26);
                right: rem(16);
            }

            @include tablet-down {
                width: rem(20);
                height: rem(21);
                right: rem(10);
            }

            &-disable {
                opacity: 0.2;
                cursor: inherit;
            }
        }

        &_textarea {
            position: relative;
            height: 100%;

            @include tablet-down {
                max-height: 60%;
            }

            textarea {
                height: rem(85);
                border-radius: rem(42);
                background-color: #000;
                border: 1px solid #fff;
                opacity: 0.65;
                box-shadow: none;
                font: inherit;
                outline: 0;
                padding-top: rem(5);
                padding-bottom: rem(5);
                padding-left: rem(43);
                padding-right: rem(43);
                resize: none;
                width: 100%;
                color: $white;
                @include font-base(53, 64);

                @include max-large-desktop-down {
                    height: rem(60);
                    border-radius: rem(30);
                    padding-left: rem(22);
                    padding-right: rem(22);
                    padding-top: rem(6);
                    @include font-base(32, 42);
                }

                @include max-desktop-down {
                    height: rem(40);
                    border-radius: rem(24);
                    padding-left: rem(20);
                    padding-right: rem(20);
                    padding-top: rem(6);
                    @include font-base(20, 24);
                }

                @include tablet-down {
                    height: rem(36);
                    border-radius: rem(22);
                    padding-left: rem(22);
                    padding-right: rem(22);
                    padding-top: rem(7);
                    @include font-base(16, 21);
                }

                &::placeholder {
                    font-family: $font-din-engschrift;
                    font-weight: normal;
                    text-align: left;
                    color: #8e8e8e;

                    @include max-large-desktop-down {
                        @include font-base(32, 42);
                    }

                    @include max-desktop-down {
                        @include font-base(20, 24);
                    }

                    @include tablet-down {
                        @include font-base(16, 21);
                    }
                }

                &:focus {
                    border-color: #2684ff;
                }
            }

            &.error {
                textarea {
                    border-color: #f00;

                    &::placeholder {
                        color: #f00;
                    }
                }
            }
        }
    }

    &_left {
        flex: 0 0 60%;
        height: 100%;
        padding-bottom: 2%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        @include max-large-desktop-down {
            padding-bottom: 3.2%;
        }

        @include max-desktop-down {
            padding-bottom: 1.8%;
        }

        @include tablet-down {
            padding-bottom: 0.7%;
            // flex: 0 0 70%;
        }

        &_toggleComment {
            position: absolute;
            left: rem(-73);
            bottom: calc(500 / 1024 * 100%);
            width: rem(44);
            height: rem(54);
            background-image: url('~assets/icons/ic_double_left_arrow.svg');
            background-size: 60%;
            background-position: center;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 11;
            background-color: rgba(0, 0, 0, 0.5);
            border-top-right-radius: rem(6);
            border-bottom-right-radius: rem(6);
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            @include max-desktop-down {
                left: rem(-24);
                width: rem(24);
                height: rem(34);
            }

            @include tablet-down {
                left: rem(-20);
                width: rem(20);
                height: rem(26);
            }

            &-hideComment {
                transform: scale(-1);
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-top-left-radius: rem(6);
                border-bottom-left-radius: rem(6);
            }
        }

        &_comments {
            position: relative;
            height: 50%;
            width: 100%;

            .o-liveComment {
                position: absolute;
                bottom: 0;
                left: 0;
            }

            &-hideComment {
                .o-liveComment {
                    left: -110%;

                    &_scrollDown {
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }
        }

        &-replay {
            width: 40%;
            position: absolute;
            top: 0;
            left: 0;
            height: 90%;
            padding-left: rem(24);

            #{$root}_left_toggleComment {
                left: 0;
            }

            &_comments {
                position: relative;
                height: 60%;
                width: 100%;
            }

            .o-replayComment {
                position: absolute;
                bottom: 0;
                left: 0;

                &-hideComment {
                    left: -110%;

                    .o-replayComment_scrollDown {
                        opacity: 0;
                        visibility: hidden;
                    }
                }

                .o-replayComment_item {
                    max-width: 100%;
                }
            }
        }
    }

    &_right {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex: 0 0 40%;
        padding-bottom: 1.5%;

        @include max-large-desktop-down {
            padding-bottom: 2.8%;
        }

        @include max-desktop-down {
            padding-bottom: 1.2%;
        }

        @include tablet-down {
            padding-bottom: 0.5%;
        }

        &-isOrder24h,
        &-replay {
            justify-content: flex-end;

            .t-livestream_cart {
                flex-shrink: 0;
                padding-right: 10%;
            }
        }

        &-replay {
            width: auto;
            position: absolute;
            top: 80%;
            right: 5%;
            height: 10%;
        }
    }

    &_voucher {
        width: rem(120);
        height: rem(120);
        padding: rem(4);
        background-color: $black;
        border: 1.58px solid $white;
        opacity: 0.65;
        border-radius: 50%;
        position: relative;
        z-index: 1;
        cursor: pointer;

        @include max-large-desktop-down {
            width: rem(90);
            height: rem(90);
        }

        @include max-desktop-down {
            width: rem(70);
            height: rem(70);
        }

        @include tablet-down {
            width: rem(45);
            height: rem(45);
        }

        &::after {
            position: absolute;
            content: '';
            background: url('~assets/icons/ic_gift.svg') no-repeat;
            background-size: 100% 100%;
            background-position: center center;
            width: 60%;
            height: 60%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &-ring {
            &::after {
                animation: tilt-shaking 0.5s infinite;
            }

        }
    }

    &_refreshGifts {
        position: relative;
        width: rem(120);
        height: rem(120);
        padding: rem(4);
        background-color: $black;
        border: 1.58px solid #fff;
        opacity: 0.65;
        border-radius: 50%;
        cursor: pointer;

        @include max-large-desktop-down {
            width: rem(90);
            height: rem(90);
        }

        @include max-desktop-down {
            width: rem(70);
            height: rem(70);
        }

        @include tablet-down {
            width: rem(45);
            height: rem(45);
        }

        &::after {
            position: absolute;
            content: '';
            background: url('~assets/icons/ic_refresh_gift.svg') no-repeat;
            background-size: 100% 100%;
            background-position: center center;
            width: 60%;
            height: 60%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &-loading {
            opacity: 0.3;
            pointer-events: none;
        }
    }

    &_cart {
        width: rem(120);
        height: rem(120);
        padding: rem(4);
        background-color: $black;
        border: 1.58px solid $white;
        opacity: 0.65;
        border-radius: 50%;
        position: relative;
        z-index: 1;
        cursor: pointer;

        @include max-large-desktop-down {
            width: rem(90);
            height: rem(90);
        }

        @include max-desktop-down {
            width: rem(70);
            height: rem(70);
        }

        @include tablet-down {
            width: rem(45);
            height: rem(45);
        }

        &::after {
            position: absolute;
            content: '';
            background: url('~assets/icons/ic_cart.svg') no-repeat;
            background-size: 100% 100%;
            background-position: center center;
            width: 60%;
            height: 60%;
            top: 55%;
            left: 55%;
            transform: translate(-50%, -50%);
        }

        &-outOfStock {
            opacity: 0.3;
            pointer-events: none;
        }
    }

    &_voucher,
    &_cart,
    &_refreshGifts {
        &-disabled {
            pointer-events: none;
            opacity: 0.2;
            cursor: inherit;
        }

        &-disabled-active {
            opacity: 0.2;
        }
    }

    &_controlWrap {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 4;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0 rem(73);

        @include max-desktop-down {
            padding: rem(24);
        }

        @include tablet-down {
            padding: rem(16) rem(20);
        }

        &-isOrder24h,
        &-replay {
            @include iphone-large-lands {
                padding: rem(16) rem(12);
            }
        }

        &-replay {
            height: 90%;

            @include iphone-large-lands {
                padding: rem(16) rem(12);
            }
        }
    }

    &_control {
        background-color: transparent;
        opacity: 1;
        visibility: visible;
        transition: all 0.3s ease-in-out;

        &_wrapper {
            display: flex;
            height: 100%;
            margin-left: rem(-10);
            margin-right: rem(-10);
            width: 100%;

            @include tablet-down {
                margin-left: -4px;
                margin-right: -4px;
            }
        }

        &_comment {
            display: flex;
            flex-grow: 1;
            justify-content: center;
            padding-top: rem(16);
            position: relative;
        }

        &_error {
            // color: $kuCrimson;
            font-size: rem(14);
            margin-top: rem(4);
        }

        &-hideComment {
            opacity: 0;
            visibility: hidden;
        }
    }

    &:not(.fullscreen) {
        .o-voucher_phase2_cart_top_title {
            @include max-large-desktop-down {
                width: 90%;
                @include font-base(20, 24);
            }

            @include max-desktop-down {
                @include font-base(17, 21);
            }

            @include desktop-down {
                @include font-base(15, 17);
            }

            @include tablet-down {
                @include font-base(13, 16);
            }
        }

        .o-voucher_phase3_cart_top_title {
            @include max-large-desktop-down {
                width: 90%;
                @include font-base(19, 22);
            }

            @include max-desktop-down {
                @include font-base(16, 19);
            }

            @include desktop-down {
                @include font-base(12, 15);
            }

            @include tablet-down {
                @include font-base(10, 12);
            }

            @include iphone-large-lands {
                @include font-base(9, 12);
            }

            @include mobile-lands {
                @include font-base(8, 11);
            }
        }

        .o-voucher_phase2-isSave {
            @include iphone-large-lands {
                height: rem(65);

                .o-voucher_phase2_thumbnail {
                    width: rem(50);
                    left: rem(-25);
                }

                .o-voucher_phase2_cart_top_title {
                    @include font-base(11, 13);
                }

                .o-voucher_phase2_cart_bottom_save {
                    @include font-base(12, 14);
                }
            }
        }

        .o-voucher_phase3-isSave {
            @include iphone-large-lands {
                width: calc(300 / 540 * 100%);
            }

            @include mobile-lands {
                height: rem(94);
                width: calc(320 / 540 * 100%);
            }

            @include iphone-medium-lands {
                width: calc(350 / 540 * 100%);
            }
        }

        .t-livestream_vouchers {
            @include iphone-large-lands {
                top: 35%;
                height: 62%;
            }

            &_item {
                @include iphone-large-lands {
                    margin-top: rem(4);
                    margin-bottom: rem(8);
                }
            }
        }

        .o-voucher_notify {
            .iconWarning {
                @include iphone-large-lands {
                    width: rem(24);
                    height: rem(24);
                }

            }

            &_text {
                @include iphone-large-lands {
                    @include font-base(8.5, 11);
                }
            }
        }
    }
}

@keyframes countdown {
    from {
        stroke-dashoffset: 0;
    }

    to {
        stroke-dashoffset: 200;
    }
}

@keyframes tilt-shaking {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    25% {
        transform: translate(-50%, -50%) rotate(5deg);
    }

    50% {
        transform: translate(-50%, -50%) rotate(0eg);
    }

    75% {
        transform: translate(-50%, -50%) rotate(-5deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
}
