.t-waitingLivestream {
    max-width: rem(1080);
    margin: 0 auto;

    &_button {
        max-width: rem(320);
        margin: rem(24) auto 0;
        
        .a-button {
            border-radius: 12px;
            border: 2px solid var(--dkngaystroke, #ecbc79);
            background: var(--DKNGAY, linear-gradient(87deg, #ecbc79 0.57%, #bc8b42 7.36%, #edd79d 25.89%, #fff3bf 51%, #ecd69c 75.3%, #bc8b42 88.26%, #ecbc79 98.19%));

            &_wrapper {
                background: none;
            }
        }
    }

    .block {
        margin-top: rem(16);

        @include tablet-down {
            margin-top: rem(8);
        }
    }
}
