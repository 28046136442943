.o-container {
    @include break-min(1366px) {
        margin-left: auto;
        margin-right: auto;
        max-width: 1366px;
        padding-left: rem(61);
        padding-right: rem(61);
    }

    &-noPaddingRight {
        padding-right: 0;
    }

    &-noPaddingLeft {
        padding-left: 0;
    }

    &-fullScreen {
        padding-left: 0;
        padding-right: 0;
    }
}
