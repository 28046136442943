.o-popup {
    $root: &;
    border: 0;
    color: $white;
    outline: 0;
    bottom: rem(8);
    max-width: rem(680);
    position: fixed;
    width: 100%;
    @include scroll-bars(8, $dim-gray);
    display: flex;
    flex-direction: column;
    height: calc(100vh - 161px);
    overflow: hidden;
    border-radius: rem(21);

    @include max-large-desktop-down {
        max-width: rem(580);
    }

    @include max-desktop-down {
        max-width: rem(520);
    }

    @include tablet-up {
        height: calc(100vh - 120px - 5px) !important;
        bottom: rem(5);
    }

    @include max-desktop {
        height: calc(100vh - 140px - 5px) !important;
    }

    @include tablet-down {
        max-width: calc(100vw - 40px);
    }

    @include iphone-large-lands {
        height: 95% !important;
        bottom: rem(10);
    }

    &_shadow {
        position: relative;
        height: 35px;

        @include iphone-large-lands {
            height: 2px;
        }

        &::after {
            content: '';
            position: absolute;
            top: 12px;
            left: 50%;
            width: 93%;
            height: 8px;
            background: linear-gradient(rgb(0 0 0 / 35%), rgb(32 32 32 / 39%), rgb(0 0 0 / 30%));
            z-index: 1;
            transition: 0.4s ease;
            border-radius: 50%;
            transform: translate(-50%, 0);
            box-shadow: 0 0 0 0 rgb(0 0 0 / 10%), 0 0 7px 5px rgb(0 0 0 / 37%);
        }
    }

    &_heading {
        margin-bottom: rem(24);

        @include mobile-down {
            margin-bottom: rem(16);
        }
    }

    &-register {
        max-width: rem(1020);
    }

    &-otp,
    &-success {
        max-width: rem(580);

        #{$root}_title {
            span {
                @include font-base(24, 40);
            }
        }

        #{$root}_heading {
            margin-bottom: rem(4);
        }

        #{$root}_body {
            padding: rem(41) rem(48) rem(29);

            @include tablet-down {
                padding-left: rem(24);
                padding-right: rem(24);
            }
        }
    }

    &-success {
        max-width: rem(600);

        #{$root}_body {
            padding: rem(47) rem(31);
        }

        #{$root}_button {
            max-width: rem(214);
        }
    }

    &_title {
        @include adjust-flex();
        position: relative;
        text-align: center;
        text-transform: uppercase;

        // &:not(#{$root}-otp) {
        //   padding: rem(10) 0;
        // }


        span {
            @include text-background($linear-seal-brown);
            font-weight: 900;
            position: relative;
            @include font-base(32, 48);

            @include tablet-down {
                @include font-base(20, 32);
            }

            @include mobile-down {
                @include font-base(18, 28);
            }

        }
    }

    &_close {
        cursor: pointer;
        height: rem(22);
        position: absolute;
        right: rem(23);
        top: rem(20);
        width: rem(22);
        z-index: 3;
        background: url('~assets/icons/ic_close.svg') no-repeat center/100% auto;
    }

    &:focus {
        outline: 0;
    }

    &-overlay {
        animation: none;
        background: rgba($black, 0.3) !important;
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: $z-modal;

        &::after {
            backdrop-filter: blur(8px);
            background: rgba($black, 0.74) !important;
            content: '';
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: -1;
        }
    }

    &_body {
        overflow: auto;
        position: relative;
        border-radius: rem(21);
        background: linear-gradient(#8a8a8a 0%, #fff 15.78%, #fff 75.79%, #a4a4a4 100%);
        border-right: 2px solid rgba(0, 0, 0, 0.4);
        border-left: 2px solid rgba(0, 0, 0, 0.4);
        flex: 1;
        height: calc(100vh - 161px - 40px - 35px) !important;

        @include max-desktop-down {
            height: calc(100vh - 100px - 40px - 35px) !important;
        }

        &::before {
            content: '';
            top: 0;
            left: 0;
            position: absolute;
            background: transparent url('~assets/images/background-popup.png') no-repeat;
            background-size: cover;
            background-position: center 89%;
            min-height: 60vh;
            width: 100%;
            z-index: 1;

            @include max-desktop-down {
                min-height: 60vh;
            }

            @include mobile-down {
                min-height: 50vh;
                background-position: center 78%;
            }

            @include iphone-large-lands {
                min-height: 70vh;
                height: 100%;
                background-position: center 88%;
            }
        }

        &::after {
            background: linear-gradient(90deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.9) 10%, rgba(255, 255, 255, 0.99) 12%, rgba(255, 255, 255, 0.9) 88%, rgba(255, 255, 255, 0.9) 90%, rgba(255, 255, 255, 0.4) 100%);
            content: '';
            display: block;
            height: 2px;
            left: 50%;
            top: -1px;
            transform: translateX(-50%);
            pointer-events: none;
            position: absolute;
            width: 80%;
            z-index: 2;
        }
    }

    &-xacnhan2 {
        #{$root}_body {
            &::before {
                background-image: url('~assets/images/background-popup-xn2.png');
            }
        }
    }

    &-power1 {
        #{$root}_body {
            &::before {
                background-image: url('~assets/images/background_popup_power1.png');
            }
        }
    }

    &-gtx {
        #{$root}_body {
            &::before {
                background-image: url('~assets/images/bg_popup_phase3.png');
            }
        }

        .banner-mobile-gtx {
            background: $white;
            display: none;

            @include mobile-down {
                display: block;
            }

            @include iphone-large-lands {
                display: none;
            }
        }
    }

    &_content {
        #{$root}-otp & {
            margin-top: rem(16);
        }
    }

    &_button {
        margin: 0 auto;
        max-width: rem(230);

        &.mobile-reverse {
            @include mobile-down {
                display: flex;
                flex-direction: column-reverse;
            }

            .a-button {
                &:first-child {
                    @include mobile-down {
                        margin-top: rem(16);
                    }
                }

                &:last-child {
                    margin-top: 0 !important;
                }
            }
        }

        &.flex {
            display: flex;
            justify-content: space-between;
            max-width: 100%;

            .a-button {
                max-width: rem(200);
            }
        }
    }
}

.o-modal-portal-open {
    opacity: 1;
}

.reactmodal-body-open,
.reactmodal-html-open {
    overflow: hidden;
}

.ReactModal__Overlay {
    opacity: 0;
    transform: scale(0) translateX(-100px);
    transition: all 500ms ease-in-out;
    background-color: transparent !important;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    transform: scale(1) translateX(-100px);
}

.ReactModal__Overlay--before-close {
    opacity: 0;
    transform: scale(0);
}

.ReactModal__Overlay {
    align-items: center;
    // background: rgba($black, 0.3) !important;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    // transform: translateY(-100%);
    transition: all 500ms ease-in-out;
    z-index: $z-modal;

    &::after {
        // background: rgba($black, 0.74) !important;
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    &--open {
        overflow: hidden;

        @include tablet-up {
            padding-right: rem(7);
        }
    }

    &--after-open {
        opacity: 1;
        transform: scale(1);
    }

    &--before-close {
        opacity: 0;
        transform: scale(0);
    }
}

.overflow {
    overflow: hidden;
}
