.t-deviceoriented {
    height: 100vh;
    position: fixed;
    width: 100vw;
    z-index: 103;

    &-logo {
        z-index: 1;
        left: rem(20);
        max-height: rem(50);
        max-width: rem(100);
        position: absolute;
        top: rem(20);
    }

    .speedWithCastrol,
    .luachonso1 {
        @include mobile-down {
            max-width: rem(322);
        }
    }

    &_content {
        z-index: 1;
        font-family: $font-din-engschrift;
        font-weight: normal;
        @include font-base(26, 32);
        text-align: center;
        color: $black;
        margin-top: 10%;
    }

    &_image {
        width: 100%;
        max-width: calc(54 / 414 * 100%);
        margin-top: rem(32);
        margin-left: auto;
        margin-right: auto;
    }
}
