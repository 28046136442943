// fonts  
@font-face {
    font-family: 'CastrolSansConML';
    src: url('#{$font-path}CastrolSansConML-Medium.woff2') format('woff2'),
        url('#{$font-path}CastrolSansConML-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DinEngschrift';
    src: url('#{$font-path}NEODINEngschrift.woff2') format('woff2'),
        url('#{$font-path}NEODINEngschrift.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('#{$font-path}Montserrat-Bold.woff2') format('woff2'),
        url('#{$font-path}Montserrat-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('#{$font-path}MyriadPro-Regular.woff2') format('woff2'),
        url('#{$font-path}MyriadPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GMV DIN Pro Cond';
    src: url('#{$font-path}GMVDINPro-CondMedium.woff2') format('woff2'),
        url('#{$font-path}GMVDINPro-CondMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GMV DIN Pro Cond Black';
    src: url('#{$font-path}GMVDINPro-CondBlack.woff2') format('woff2'),
        url('#{$font-path}GMVDINPro-CondBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GMV DIN Pro Cond';
    src: url('#{$font-path}GMVDINPro-CondBold.woff2') format('woff2'),
        url('#{$font-path}GMVDINPro-CondBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat Regular';
    src: url('#{$font-path}Montserrat-Regular.woff2') format('woff2'),
        url('#{$font-path}Montserrat-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat Black';
    src: url('#{$font-path}Montserrat-Black.woff2') format('woff2'),
        url('#{$font-path}Montserrat-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('#{$font-path}Inter-Bold.woff2') format('woff2'),
        url('#{$font-path}Inter-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
