.t-forgotPasswordForm {
    max-width: rem(358);
    margin: 0 auto;

    &_subTitle {
        margin-bottom: rem(12);
    }

    &_button {
        max-width: rem(270);
        margin: rem(24) auto 0;

        .a-button {
            border-radius: 12px;
            border: 2px solid var(--dkngaystroke, #ecbc79);
            background: var(--DKNGAY, linear-gradient(87deg, #ecbc79 0.57%, #bc8b42 7.36%, #edd79d 25.89%, #fff3bf 51%, #ecd69c 75.3%, #bc8b42 88.26%, #ecbc79 98.19%));

            &_wrapper {
                background: none;
            }
        }
    }

    &_form {
        margin: rem(4) auto 0;
    }

    &_wrapRegister {
        @include adjust-flex();
        margin-top: rem(12);
    }

    &_register {
        margin-left: rem(4);
        cursor: pointer;

        .a-text {
            @include text-background($linear-seal-brown); }
    }

    &_input {
        .a-input_errorMessage {
            span {
                @include font-base(20,32);
                @include mobile-down {
                    @include font-base(16,24);
                }
            }
        }
    }
}
