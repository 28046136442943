// @mixin contentRegister($imageBackground: 'background.png') {
//     background-image: url('~assets/images/register/' + $imageBackground);
// }

@mixin button-register {
    display: flex;
    justify-content: center;
    gap: rem(40);
    align-items: center;
    position: absolute;
    bottom: rem(32);
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    z-index: 1;

    @include iphone-large-lands {
        display: none;
    }

    @include tablet-down {
        bottom: rem(49);
    }

    &-horizontal {
        display: none;

        @include iphone-large-lands {
            display: flex;
            position: relative;
            left: auto;
            gap: rem(40);
            transform: none;
            bottom: auto;
            width: fit-content;
            margin: rem(40) auto 0;
        }
    }

    .a-button_icon {
        @include tablet-up {
            width: rem(34);
            height: rem(39);
            margin-right: rem(10);

            .a-icon {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.p-register {
    position: relative;
    height: 100%;

    &_error {
        max-width: rem(420);
        margin-left: auto;
        margin-right: auto;

        @include tablet-down {
            max-width: rem(300);
        }
    }

    &_step1 {
        &_form {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }

        &-heading {
            @include font-base(53, 64);
            padding-left: rem(12);
            padding-right: rem(12);
            font-family: $font-din-engschrift;
            font-weight: normal;
            text-align: center;
            color: $spanish-green;
            text-transform: uppercase;
            margin-top: rem(10);

            @include max-desktop-down {
                @include font-base(42, 58);
            }


            @include tablet-down {
                @include font-base(35, 42);
            }
        }

        .eventOnline {
            width: rem(243);
            height: rem(83);

            // @include max-large-desktop-down {
            //     width: rem(220);
            //     height: rem(80);
            // }

            @include max-large-desktop-down {
                width: rem(180);
                height: rem(70);
            }

            @include max-desktop-down {
                width: rem(161);
                height: rem(55);
            }
        }

        .speedWithCastrol {
            max-width: rem(540);
            height: rem(172);

            // @include max-large-desktop-down {
            //     max-width: rem(480);
            //     height: rem(155);
            // }

            @include max-large-desktop-down {
                max-width: rem(390);
                height: rem(130);
            }

            @include max-desktop-down {
                max-width: rem(358);
                height: rem(114);
            }
        }

        &-input {
            margin-left: auto;
            margin-right: auto;
            margin-top: 4%;
            margin-bottom: rem(30);
            width: calc(673 / 1920 * 100%);

            @include max-desktop-down {
                margin-top: 2%;
            }

            @include desktop-down {
                margin-top: 1%;
            }

            @include tablet-down {
                width: calc(280 / 414 * 100%);
                margin-bottom: rem(60);
            }

            @include mobile-down {
                // margin-top: calc(300 / 896 * 100%);
                margin-bottom: rem(10);
            }


            @include iphone-large-lands {
                margin-bottom: 0;
            }
        }

        &-button {
            @include button-register();
        }

        &_popup {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            z-index: 2;
            position: relative;
            height: 100%;

            &-title,
            &-description {
                font-family: $font-din-engschrift;
                font-weight: normal;
                @include font-base(39, 47);
                text-align: center;
                color: #ea1c27;

                @include max-desktop-down {
                    @include font-base(36, 46);
                }

                @include tablet-down {
                    @include font-base(32, 39);
                }

                &-registered {
                    color: $spanish-green;

                    a {
                        color: #ea1c27;
                    }
                }
            }

            &-description {
                margin-top: rem(32);
            }

            &_content {
                flex: 1;
            }

            &_bottom {
                display: flex;
                justify-content: center;
            }
        }
    }

    &_step2 {
        .t-background_content {
            padding-bottom: rem(60);

            @include max-desktop-down {
                padding-bottom: rem(10);
            }

            @include tablet-down {
                padding-bottom: rem(60);
            }

            @include mobile-down {
                padding-bottom: rem(30);
            }
        }

        &_top {
            display: flex;
            justify-content: center;
            align-items: center;

            @include tablet-down {
                flex-direction: column;
            }
        }

        &_right {
            width: 30%;

            @include max-desktop-down {
                width: 35%;
            }

            @include tablet-down {
                width: 100%;
            }
        }

        &-heading {
            @include font-base(53, 64);
            padding-left: rem(12);
            padding-right: rem(12);
            font-family: $font-din-engschrift;
            font-weight: normal;
            text-align: center;
            color: $spanish-green;
            margin-top: rem(10);

            @include max-large-desktop-down {
                @include font-base(32, 48);
                padding-left: rem(12);
                padding-right: rem(30);
            }

            @include max-desktop-down {
                @include font-base(28, 32);
                margin-top: 0;
            }

            @include tablet-down {
                @include font-base(26, 31);
                padding-right: rem(12);
                width: auto;
            }
        }

        .eventOnline {
            width: rem(240);
            height: rem(75);
            margin: rem(36) auto 0;

            @include max-large-desktop-down {
                width: rem(128);
                height: rem(43);
            }

            @include max-desktop-down {
                width: rem(120);
                height: rem(40);
                margin-top: rem(20);
            }
        }

        .speedWithCastrol {
            max-width: rem(570);
            height: rem(180);
            margin: 0 auto;
            padding-bottom: rem(60);

            @include max-large-desktop-down {
                max-width: rem(284);
                height: rem(91);
            }

            @include max-desktop-down {
                max-width: rem(240);
                height: rem(85);
            }
        }

        &_info {
            display: flex;
            justify-content: center;
            // padding-left: rem(50);
            // padding-right: rem(50);
            gap: rem(60);
            align-items: flex-end;
            margin-top: rem(20);
            margin-bottom: rem(40);
            max-width: 90%;
            width: 100%;
            margin-left: auto;
            margin-right: auto;

            @include tablet-down {
                flex-direction: column;
                padding-left: rem(20);
                padding-right: rem(20);
                gap: inherit;
                align-items: center;
                margin-top: auto;
            }

            @include desktop-up {
                &_item {
                    min-width: rem(200);
                }
            }
        }

        &_input {
            display: flex;
            align-items: center;
            padding: rem(4) rem(40);
            @include font-base(28, 34);
            width: 100%;
            max-width: rem(420);
            height: 100%;
            min-height: rem(62);
            color: $white;
            flex: 1;
            background: linear-gradient(#39a970 0%, #009a4b 15.78%, #009a4b 60.76%, #009a4b 72.76%, #28945c 80.76%, #145d37 99.88%);
            border: 2px solid #c2c2c3;
            font-family: $font-din-engschrift;
            font-weight: normal;
            text-align: left;
            border-radius: rem(50);
            position: relative;

            @include max-desktop-down {
                margin-top: rem(40);
                margin-left: auto;
                margin-right: auto;
                border-radius: rem(24);
                @include font-base(21, 28);
            }

            @include tablet-down {
                max-width: rem(300);
                width: calc(100vw - 60px);
                @include font-base(18, 21);
                min-height: rem(52);
                height: fit-content;
            }

            &-hasLabel {
                margin-top: rem(10);
            }

            &-label {
                font-family: $font-din-engschrift;
                font-weight: normal;
                text-align: left;
                color: #008c40;
                @include font-base(34, 41);
                margin-bottom: rem(6);
                // width: calc(100vw - 60px);
                margin: 0 auto;

                @include max-desktop-down {
                    max-width: rem(420);
                    margin-top: rem(10);
                    @include font-base(21, 28);
                }

                @include tablet-down {
                    max-width: rem(300);
                    @include font-base(19, 22);
                }
            }
        }

        &_button {
            @include button-register();
        }

        &_incorrectInfo {
            margin-left: auto;
            margin-right: auto;
            width: fit-content;
            // margin-top: rem(60);
            margin-bottom: rem(60);

            @include max-large-desktop-down {
                margin-bottom: rem(40);
            }

            @include max-desktop-down {
                margin-bottom: rem(30);
            }
        }

        &_editIcon {
            position: absolute;
            right: 5%;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
        }
    }

    &_step3 {
        &-heading {
            @include font-base(48, 60);
            padding-left: rem(12);
            padding-right: rem(12);
            font-family: $font-din-engschrift;
            font-weight: normal;
            text-align: center;
            color: $spanish-green;
            width: 45%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: rem(12);

            @include max-large-desktop-down {
                @include font-base(42, 58);
                width: 50%;
                margin: rem(6) auto rem(20);
            }

            @include max-desktop-down {
                @include font-base(32, 28);
                width: 50%;
            }

            @include tablet-down {
                width: auto;
            }
        }

        &_termsWrap {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: rem(12);
            position: relative;
            width: calc(826 / 1920 * 100%);
            height: calc(450 / 800 * 100%);

            @include max-large-desktop-down {
                height: calc(400 / 800 * 100%);
            }

            @include tablet-down {
                width: calc(365 / 414 * 100%);
                height: calc(370 / 667 * 100%);
            }

            @include iphone-large-lands {
                height: 60vh;
            }

            &::after {
                content: '';
                position: absolute;
                bottom: -10px;
                left: 50%;
                width: 82%;
                height: 8px;
                background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
                z-index: 1;
                transition: 0.4s ease;
                border-radius: 50%;
                transform: translate(-50%, 0);
                box-shadow: 0 2px 3px 2px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(18, 13, 13, 0.19);
            }
        }

        &_terms {
            width: 100%;
            height: 100%;
            border-radius: rem(21);
            border: rem(2) solid #707070;
            background: linear-gradient(#c7c4c4 0%, #fff 12.78%, #fff 80%, #c7c4c4 100%);
            overflow: hidden auto;
            position: relative;
            padding-left: rem(20);
            padding-right: rem(20);

            @include tablet-down {
                border-width: rem(1);
            }

            &-inner {
                position: relative;
                z-index: 1;
                padding: rem(30) 0;
            }

            * {
                font-family: $font-gmv-din-pro-cond !important;
                @include font-base(21, 28);
                font-weight: normal;
                color: #666363;

                ul {
                    padding-left: rem(30);
                }

                @include mobile-down {
                    @include font-base(16, 21);
                }
            }
        }

        &_agree {
            width: calc(826 / 1920 * 100%);
            margin-right: auto;
            margin-left: auto;
            margin-top: rem(12);
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding-left: 5%;

            @include max-desktop-down {
                margin-top: rem(8);
            }

            @include desktop-down {
                padding-left: 0;
            }

            @include tablet-down {
                width: 70%;
                padding-left: 5%;
            }


            @include small-mobile {
                width: 95%;
                padding-left: rem(20);
            }

            .text-error {
                padding-left: rem(46);
            }

            &:nth-child(2) {
                margin-top: rem(16);
            }
        }

        &_textTerms {
            font-family: $font-din-engschrift;
            font-weight: normal;
            @include font-base(33, 40);
            text-align: left;
            color: #00441d;

            @include max-large-desktop-down {
                @include font-base(26, 31);
            }

            @include max-desktop-down {
                @include font-base(24, 32);
            }

            @include tablet-down {
                @include font-base(21, 31);
            }

            @include mobile-down {
                @include font-base(18, 21);
            }
        }

        .eventOnline {
            width: rem(240);
            height: rem(75);
            margin: rem(36) auto 0;


            @include max-desktop-down {
                max-width: rem(260);
                height: rem(85);
            }

            @include tablet-down {
                width: rem(128);
                height: rem(43);
            }
        }

        .speedWithCastrol {
            max-width: rem(570);
            height: rem(180);
            margin: 0 auto;
            padding-bottom: rem(60);

            @include max-desktop-down {
                max-width: rem(500);
                height: rem(180);
            }

            @include tablet-down {
                max-width: rem(284);
                height: rem(91);
            }
        }

        &_button {
            @include button-register();
        }

        &_incorrectInfo {
            margin-left: auto;
            margin-right: auto;
            width: fit-content;
            margin-top: rem(60);

            @include tablet-down {
                margin-top: rem(40);
            }
        }
    }

    &_step4 {
        .t-background_content {
            margin-left: auto;
            margin-right: auto;
            // padding-bottom: rem(60);
        }


        &-title,
        &-description {
            font-family: $font-din-engschrift;
            font-weight: normal;
            @include font-base(53, 63);
            text-align: center;
            color: $spanish-green;

            @include max-large-desktop-down {
                @include font-base(42, 58);
            }

            @include max-desktop-down {
                @include font-base(32, 44);
            }

            @include tablet-down {
                @include font-base(26, 31);
            }

            a {
                color: #ea1c27;
            }
        }

        &-title {
            padding-top: 5%;

            @include max-desktop-down {
                padding-top: 1%;
            }

            @include iphone-large-lands {
                padding-top: rem(40);
            }
        }

        &-description {
            padding-top: rem(32);
        }

        .eventOnline {
            width: rem(287);
            height: rem(99);
            margin: rem(36) auto 0;

            @include max-large-desktop-down {
                max-width: rem(260);
                height: rem(85);
            }

            @include max-desktop-down {
                max-width: rem(200);
                height: rem(72);
            }

            @include tablet-down {
                width: rem(128);
                height: rem(43);
            }
        }

        .speedWithCastrol {
            max-width: rem(638);
            height: rem(204);
            margin: 0 auto;
            padding-bottom: rem(60);

            @include max-large-desktop-down {
                max-width: rem(500);
                height: rem(180);
            }

            @include max-desktop-down {
                max-width: rem(400);
                height: rem(130);
            }

            @include tablet-down {
                max-width: rem(284);
                height: rem(91);
            }
        }
    }
}
