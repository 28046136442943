.p-eventWaiting2 {
    background-image: url('~assets/images/web_frame_waiting.jpg');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile-down {
        background-image: url('~assets/images/mobile_frame_waiting.jpg');
    }

    @include ipad-large-lands {
        background-image: url('~assets/images/web_frame_waiting.jpg');
    }
}
