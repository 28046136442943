@mixin small-title {
    position: relative;

    &::before {
        background: linear-gradient(83.85deg, #4376b8 -4.64%, #104d82 29.01%, #104d82 54.48%, #4376b8 90.05%);
        bottom: -5px;
        content: '';
        height: 2px;
        left: 0;
        position: absolute;
        width: rem(54);
    }

    .a-heading {
        text-align: left;
        @include font-base(18, 24);
    }
}

.o-simiPopup {
    $root: &;
    background-image: url('~assets/images/bg-popup-blue.png') !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    border: 0;
    color: $white;
    max-height: rem(640);
    max-width: rem(580);
    outline: 0;
    overflow: auto;
    position: fixed;
    width: 100%;
    @include tablet-down {
        max-height: 94%;
    }

    @include scroll-bars;

    @include mobile-tablet {
        max-width: 80vw;
    }

    @include mobile-down {
        max-width: 90vw;
    }

    &-player {
        background-image: none !important;
        max-width: 120vh;
        @include tablet-up {
            max-height: unset;
            overflow: hidden;
        }
        @include tablet-down {
            padding: rem(5);
        }
        @include mobile-tablet {
            max-width: 70vw;
        }

        &.video {
            @include tablet-down {
                height: 100%;
                max-height: 90%;
            }
            @include mobile-tablet {
                max-width: 65vw;
            }
            #{$root}_body {
                @include tablet-down {
                    flex-wrap: wrap;
                    justify-content: space-between;
                }
            }
        }

        &:has(.t-playerPopup_iframe) {
            padding: 0 !important;
        }

        &.image {
            max-height: unset;
            max-width: 140vh;
            overflow: unset;

            #{$root}_wrapper {
                max-height: 90vh;
                overflow: auto;
                @include scroll-bars;
            }
        }
    
        &.iframe {
            // max-width: 143vh;
            max-width: 100%;
            height: 100%;
            max-height: 100%;

            // @include tablet-down {
            //   max-width: 150vh;
            // }

            .o-simiPopup_close {
                visibility: hidden;
            }
        }
        #{$root} {
            &_wrapper {
                display: flex;
                height: 100%;
                padding: 0;
                width: 100%;
                @include mobile-down {
                    padding: 0 !important;
                }
            }

            &_body {
                display: flex;
                margin-top: 0;
                width: 100%;
            }
        }
    }

    &_wrapper {
        padding: rem(52) rem(50);
        @include mobile-down {
            padding: rem(24) !important;
        }
    }

    &_title {
        position: relative;

        &.bigger {
            &::before {
                display: none;
            }
        }

        // &::before {
        //   background: linear-gradient(90deg, #ceb780 0%, #c59148 17%, #dcbe88 33%, #ebddb3 45%, #e7d5a8 47%, #d5b074 59%, #c99a54 67%, #c59148 72%, #c48e42 76%, #c18430 90%, #c0802a 100%);
        //   bottom: -5px;
        //   content: '';
        //   height: 2px;
        //   left: 0;
        //   position: absolute;
        //   width: rem(54);
        // }
    }

    &_body {
        margin-top: 7px;
    }

    &_label {
        margin-bottom: rem(16);
        margin-top: rem(10);
    }

    &_content {
        background-color: $white;
        border: 1px solid $spanish-gray;
        border-radius: 5px;
        padding: rem(15) rem(8) rem(13) rem(16);

        video {
            height: unset;
        }
    }

    &_sub {
        margin: auto;
        margin-bottom: rem(15);
        max-width: rem(485);
        text-align: center;
    }


    &_button {
        display: flex;
        justify-content: center;
        margin: rem(32) auto 0;
        @include mobile-down {
            margin-top: rem(25);
        }

        &_item {
            flex: 0 0 50%;
            max-width: 50%;
            padding-left: rem(12);
            padding-right: rem(12);
            @include mobile-down {
                padding-left: rem(6);
                padding-right: rem(6);

                .a-button {
                    font-size: rem(16);
                }
            }
        }

        .a-button {
            span {
                @include font-base(14, 18);
            }
        }
    }

    &_error {
        margin-top: rem(12);
    }

    &_close {
        background-color: rgba($dark-liver, 0.6);
        border-radius: 50%;
        cursor: pointer;
        height: rem(40);
        padding: rem(5);
        position: absolute;
        right: rem(24);
        top: rem(24);
        width: rem(40);
        z-index: 9;
        @include tablet-down {
            right: rem(16);
            top: rem(16);
        }

        &::before,
        &::after {
            background-color: $white;
            content: '';
            height: 2px;
            left: 50%;
            position: absolute;
            top: 50%;
            width: 50%;
        }

        &::before {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &::after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }
}



