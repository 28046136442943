.a-input {
    $root: &;
    $r: 5px; // radius
    position: relative;
    width: 100%;

    &_errorMessage {
        color: $light-brilliant-red;
        margin-top: rem(10);
        text-align: center;
        @include font-base(18, 21);

        @include mobile-down {
            @include font-base(14, 18);
        }
    }

    &_label {
        margin-bottom: rem(2);
    }

    &_required {
        margin-left: rem(4);
        color: $light-brilliant-red;
        font-size: inherit;
    }

    &_container {
        position: relative;
        overflow: hidden;
    }

    &-h44 {
        #{$root}_inputele {
            height: rem(44);
            @include font-base(16, 24);
        }
    }

    &-h48 {
        #{$root}_inputele {
            height: rem(48);
            @include font-base(16, 24);
        }
    }

    &-h52 {
        #{$root}_inputele {
            height: rem(52);
            @include font-base(20, 32);

            @include mobile-down {
                height: rem(44);
                @include font-base(16, 24);

            }
        }
    }

    &-otp {
        position: relative;
        width: 100%;
        max-width: rem(209);
        height: rem(130);

        @include max-large-desktop-down {
            max-width: rem(120);
            height: rem(80);
        }

        @include max-desktop-down {
            max-width: rem(92);
            height: rem(62);
        }

        @include mobile-down {
            height: rem(55);
            max-width: rem(82);
        }

        input::placeholder {
            color: $white;
            font-weight: 400;
        }

        #{$root}_inputele {
            position: relative;
            border: 0;
            border-radius: rem(60);
            height: 95%;
            padding: 0;
            text-align: center;
            width: 95%;
            background: linear-gradient(#39a970 0%, #009a4b 15.78%, #009a4b 60.76%, #009a4b 72.76%, #28945c 80.76%, #145d37 99.88%);
            font-family: $font-castrol-sans-conml;
            font-weight: normal;
            color: #fff;
            font-size: rem(90);

            @include max-large-desktop-down {
                border-radius: rem(32);
                font-size: rem(46);
            }

            @include max-desktop-down {
                border-radius: rem(28);
            }

            @include desktop-down {
                border-radius: rem(26);
            }

            @include mobile-down {
                font-size: rem(32);
                border-radius: rem(26);
            }
        }

        #{$root}_container {
            position: relative;
            height: 100%;
            width: 100%;
            background: linear-gradient(#c2c2c3 0%, #c3c3c4 15.75%, #cacacb 21.92%, #d7d7d7 26.42%, #e8e8e8 30.07%, #fefefe 33.22%, #fff 33.3%, #9b9ca0 71.35%, #aaabae 72.69%, #c0c1c3 75.09%, #d1d2d2 77.93%, #dddedd 81.43%, #e4e5e4 86.35%, #e6e7e6 100%);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: rem(60);

            @include desktop-down {
                border-radius: rem(40);
            }

            @include mobile-down {
                border-radius: rem(26);
            }
        }
    }

    &-primary {
        #{$root}_container {
            height: rem(100);
            display: flex;
            background: linear-gradient(#39a970 0%, #fff 15.78%, #fff 82.76%, #39a970 99.88%);
            padding-top: rem(2);
            padding-bottom: rem(2);
            justify-content: center;
            align-items: center;

            @include max-large-desktop-down {
                height: rem(68);
            }

            @include max-desktop-down {
                height: rem(52);
            }

            @include desktop-down {
                height: rem(44);
            }
        }

        #{$root}_inputele {
            font-family: $font-din-engschrift;
            font-weight: normal;
            text-align: center;
            color: #9e9e9e;
            @include font-base(59, 70);
            border: 0;
            background: transparent;
            width: 100%;

            @include max-large-desktop-down {
                @include font-base(42, 40);
            }

            @include max-desktop-down {
                @include font-base(32, 28);
            }

            @include tablet-down {
                @include font-base(26, 31);
            }
        }
    }

    &-register {
        #{$root}_container {
            display: flex;
            padding-top: rem(2);
            padding-bottom: rem(2);
            justify-content: center;
            align-items: center;

            @include max-large-desktop-down {
                height: rem(68);
            }

            @include max-desktop-down {
                height: rem(52);
            }

            @include desktop-down {
                height: rem(44);
            }
        }

        #{$root}_inputele {
            font-family: $font-din-engschrift;
            font-weight: normal;
            color: $white;
            @include font-base(28, 34);
            border: 0;
            background: transparent;
            width: 100%;

            &::placeholder {
                color: $white;
            }

            @include max-desktop-down {
                @include font-base(21, 28);
            }

            @include tablet-down {
                @include font-base(18, 21);
            }
        }
    }

    input[type=password]:not(:placeholder-shown) {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
    }

    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input:focus {
        outline: none;
    }
}
