.t-survey {
    position: absolute;
    top: 45%;
    right: 4%;
    z-index: 6;
    width: calc(540 / 1272 * 100%);
    height: fit-content;
    overflow: hidden;
    transform: translate(0, 0) scale(1);
    transition: all 0.3s ease-in-out;
    min-height: rem(200);

    @include iphone-large-lands {
        top: 42%;
        min-height: rem(120);
    }

    &-hidden {
        top: -20%;
        visibility: hidden;
        transform: translate(0, 0) scale(0);
    }

    &_notify {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -45%) scale(0.8);
        z-index: 7;
        width: 80%;
        background-color: rgba(0, 0, 0, 0.65);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: rem(20);
        font-weight: 700;
        text-align: center;
        transition: all 0.3s ease-in-out;
        padding: rem(25) rem(32);
        border-radius: rem(40);
        opacity: 0;
        visibility: hidden;

        @include max-desktop-down {
            width: 85%;
            top: 45%;
            font-size: rem(16);
        }

        @include iphone-large-lands {
            width: 95%;
            font-size: rem(14);
            padding: rem(16) rem(20);
        }

        &-show {
            opacity: 1;
            visibility: visible;
            transform: translate(-50%, -45%) scale(1);
        }
    }

    &_loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 7;
        transform: translate(-50%, -50%);
    }

    &_list {
        display: flex;
        justify-content: space-between;
        margin: 0 -15px;
        transition: all 0.3s ease-in-out;
        transform: translate(0, 0) scale(0);

        @include iphone-large-lands {
            margin: 0 rem(-10);
        }

        &-show {
            transform: translate(0, 0) scale(1);
        }
    }

    &_item {
        width: calc(100% / 3);
        margin: 0 rem(15);
        transition: all 0.3s ease-in-out;

        @include iphone-large-lands {
            margin: 0 rem(10);
        }

        img {
            width: 100%;
            height: auto;
            cursor: pointer;
            flex-shrink: 0;
        }

        &:focus,
        &:hover,
        &:active {
            transform: scale(0.9);
        }
    }
}
