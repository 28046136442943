.a-checkbox {
    $root: &;
    display: inline-block;
    position: relative;

    &_input {
        opacity: 0;
        position: absolute;

        &:checked+#{$root}_body {
            .a-checkbox_inner {
                background-image: url('~assets/icons/ic_checked.svg');
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }

    &_label {
        cursor: pointer;
        display: flex;
        margin: 0;
    }

    &_content {
        font-family: 'SVN-Brandon Grotesque';
        color: $dark-slate-blue;
        font-size: rem(14);
        font-weight: 700;
        margin-left: rem(12);
        letter-spacing: 0.0015em;

        @include font-base(32, 40);

        @include max-large-desktop-down {
            @include font-base(26, 31);
        }

        @include tablet-down {
            @include font-base(16, 26);
        }
    }

    &_body {
        align-items: center;
        background-color: $white;
        border-radius: 100%;
        display: flex;
        flex: 0 0 auto;
        justify-content: center;
        position: relative;
        width: rem(53);
        height: rem(53);
        background: #fff;
        border: 1.07px solid #009343;

        @include max-large-desktop-down {
            height: rem(40);
            width: rem(40);
        }

        @include max-desktop-down {
            height: rem(34);
            width: rem(34);
        }

        @include mobile-down {
            height: rem(26);
            width: rem(26);
        }

        #{$root}_label:hover & {
            opacity: 0.7;
        }

        #{$root}-disabled & {
            opacity: 0.7;
        }
    }

    &_inner {
        height: rem(49);
        width: rem(49);
        position: absolute;
        top: rem(-10);
        left: rem(13);

        @include max-large-desktop-down {
            height: rem(40);
            width: rem(40);
            left: rem(9);
        }

        @include max-desktop-down {
            height: rem(34);
            width: rem(34);
        }

        @include mobile-down {
            height: rem(26);
            width: rem(26);
            left: rem(6);
        }
    }

    &-disabled {
        pointer-events: none;
    }
}
