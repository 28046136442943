.p-endEvent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;

    &_image {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        visibility: hidden;
        opacity: 0;

        @include mobile-down {
            height: auto;
        }

        &-showImage {
            visibility: visible;
            opacity: 1;
        }
    }

    video {
        display: block;
        width: 100%;
        height: 100%;
        max-width: 100%;
    }

    &_video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        visibility: hidden;
        opacity: 0;
        z-index: -1;

        &-show {
            visibility: visible;
            opacity: 1;
            z-index: 1;
            background-color: $white;
        }
    }
}
