@use 'sass:math';

@mixin text-overflow ($number: 2) {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: ($number);
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin text-background($color) {
    background: $color;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

@mixin aspect-ratio($width: 1, $height: 1) {
    padding-bottom: calc(math.div($height, $width) * 100%);
}

@mixin transition($value) {
    transition: $value;
}

@mixin scroll-bars($size: 12, $thumb-color: $green-ryb, $track-color: $bg-white-smoke, $border-radius-thumb: 5, $border-thumb: 2) {

    // For Internet Explorer

    scrollbar-face-color: $thumb-color;
    scrollbar-track-color: $track-color;

    // For Google Chrome
    &::-webkit-scrollbar {
        height: rem($size);
        margin-right: rem(10);
        width: rem($size);
    }

    &::-webkit-scrollbar-thumb {
        background: $thumb-color;
        border: rem($border-thumb) solid $track-color;
        border-radius: rem($border-radius-thumb);
    }

    &::-webkit-scrollbar-track {
        background: $track-color;
    }
}

@mixin font-base($font-size: 16, $line-height: 24) {
    font-size: rem($font-size);
    line-height: rem($line-height);
}

@mixin style-bg-color() {
    &-bg-white {
        background-color: $white;
    }
}

@mixin style-font() {
    // &-brandon {
    //     font-family: $font-brandon;
    // }
}

@mixin style-font-weight() {
    &-100 {
        font-weight: 100;
    }

    &-200 {
        font-weight: 200;
    }

    &-300 {
        font-weight: 300;
    }

    &-400 {
        font-weight: normal;
    }

    &-500 {
        font-family: 'SVN-Brandon Grotesque';
        font-weight: 500;
    }

    &-600 {
        font-family: 'SVN-Brandon Grotesque';
        font-weight: 600;
    }

    &-700 {
        font-weight: bold;
    }

    &-800 {
        font-weight: 800;
    }

    &-900 {
        font-weight: 900;
    }
}

@mixin style-general() {
    &-uppercase {
        text-transform: uppercase;
    }

    &-capitalize {
        text-transform: capitalize;
    }

    &-underline {
        text-decoration: underline;
    }

    &-italic {
        font-style: italic;
    }

    &-center {
        text-align: center;
    }

    &-justify {
        text-align: justify;
    }
}

@mixin reset-button {
    appearance: none;
    background-color: none;
    border: 0;
    outline: none;
}

@mixin list-style-none {
    list-style: none;
    margin: 0;
    padding: 0;
}

@mixin adjust-flex($justify-content: center, $align-item: center) {
    align-items: $align-item;
    display: flex;
    justify-content: $justify-content;
}

$colors-design: (
    'white': $white,
    'raisinBlack': $raisin-black,
    'davysGrey': $davys-grey,
    'grayX11': $gray-x11,
    'whiteSmoke': $white-smoke,
    'snow': $snow,
    'gunmetal': $gunmetal,
    'pattrickBlue': $pattrick-blue,
    'sapphire': $sapphire,
    'spaceCadet': $space-cadet,
    'bronze': $bronze,
    'dimGray': $dim-gray,
    'darkSlateBlue': $dark-slate-blue,
    'jet': $jet,
    'satinSheenGold': $satin-sheen-gold,
    'turquoise': $turquoise,
    'outerSpace': $outer-space,
    'red': $red,
    'bronze2': $bronze2,
    'pattrickBlue08': $pattrick-blue08,
    'yaleBlue': $yale-blue,
    'gray': $gray,
    'darkCerulean': $dark-cerulean,
);

@mixin style-color {
    @each $name, $color in $colors-design {
        &-#{$name} {
            color: $color;
        }
    }
}

@mixin style-color {
    @each $name, $color in $colors-design {
        &-#{$name} {
            color: $color;
        }
    }

    &-linearCerulean {
        background-clip: text;
        background-image: $linear-dark-cerulean;
        color: transparent;
    }

    &-linearFinished {
        background-clip: text;
        background-image: $linear-finished;
        color: transparent;
    }

    &-linearBlackBlue {
        background-clip: text;
        background-image: $linear-black-cerulean;
        color: transparent;
    }

    &-linearBlackBlue2 {
        background-clip: text;
        background-image: $linear-black-cerulean2;
        color: transparent;
    }

    &-linearBlackBlue3 {
        background-clip: text;
        background-image: $linear-black-cerulean3;
        color: transparent;
    }

    &-linearBlackBlue4 {
        background-clip: text;
        background-image: $linear-black-cerulean4;
        color: transparent;
    }

    &-linearYellow {
        background-clip: text;
        background-image: $linear-yellow;
        color: transparent;
    }

    &-linearCoyote {
        background-clip: text;
        background-image: $linear-coyote;
        color: transparent;
    }

    &-linearSurveyTitle {
        background-clip: text;
        background-image: $linear-survey-title;
        color: transparent;
    }

    &-linearGold2 {
        background-clip: text;
        background-image: $linear-gold2;
        color: transparent;
    }

    &-linearGold3 {
        background-clip: text;
        background-image: $linear-gold3;
        color: transparent;
    }

    // new 
    &-linearGoldNew {
        background-clip: text;
        background-image: $linear-gold-new;
        color: transparent;
    }

    &-linearBlueText {
        background-clip: text;
        background-image: $linear-blue-text;
        color: transparent;
    }
}


@mixin style-font-size() {
    &-14x24 {
        @include font-base(14, 24);
    }

    &-14x22 {
        @include font-base(14, 22);
    }

    &-16x20 {
        @include font-base(16, 20);
    }

    &-16x22 {
        @include font-base(16, 22);
    }

    &-17x21 {
        @include font-base(17, 24);

        @include tablet-down {
            @include font-base(16, 28);
        }
    }

    &-18x21 {
        @include font-base(18, 21);
    }

    &-18x24 {
        @include font-base(18, 24);

        @include mobile-down {
            @include font-base(14, 20);
        }
    }

    &-18x28 {
        @include font-base(18, 28);

        @include mobile-down {
            @include font-base(14, 18);
        }
    }

    &-16x24 {
        @include font-base(16, 24);
    }

    &-20x24 {
        @include font-base(20, 24);

        @include mobile-down {
            @include font-base(14, 16);
        }
    }

    &-20x28 {
        @include font-base(20, 28);

        @include tablet-down {
            @include font-base(18, 25);
        }

        @include mobile-down {
            @include font-base(16, 24);
        }
    }

    &-19x30 {
        @include font-base(19.2, 30);

        @include tablet-down {
            @include font-base(18, 26);
        }

        @include mobile-down {
            @include font-base(16, 24);
        }
    }

    &-20x30 {
        @include font-base(20, 30);

        @include tablet-down {
            @include font-base(18, 26);
        }

        @include mobile-down {
            @include font-base(16, 24);
        }
    }

    &-21x31 {
        @include font-base(21, 31);

        @include tablet-down {
            @include font-base(19, 27);
        }

        @include mobile-down {
            @include font-base(17, 25);
        }
    }

    &-20x32 {
        @include font-base(20, 32);

        @include tablet-down {
            @include font-base(18, 25);
        }

        @include mobile-down {
            @include font-base(16, 24);
        }
    }

    &-24x28 {
        @include font-base(24, 28);

        @include tablet-down {
            @include font-base(20, 25);
        }
    }

    &-24x36 {
        @include font-base(24, 36);

        @include tablet-down {
            @include font-base(20, 28);
        }

        @include mobile-down {
            @include font-base(16, 24);
        }
    }

    &-24x32 {
        @include font-base(24, 32);

        @include desktop-down {
            @include font-base(20, 28);
        }

        @include mobile-down {
            @include font-base(16, 22);
        }

        @media (max-width: 374px) {
            @include font-base(14, 20);
        }
    }

    &-28x40 {
        @include font-base(28, 40);

        @include desktop-down {
            @include font-base(24, 32);
        }

        @include mobile-down {
            @include font-base(14, 22);
        }
    }

    &-30x48 {
        @include font-base(30, 48);

        @include break-max(1300px) {
            @include font-base(28, 40);
        }

        @include tablet-down {
            @include font-base(18, 34);
        }
    }

    &-34x52 {
        @include font-base(34, 52);

        @include desktop-down {
            @include font-base(28, 40);
        }

        @include mobile-down {
            @include font-base(20, 28);
        }
    }

    &-32x40 {
        @include font-base(32, 55);

        @include break-max(1300px) {
            @include font-base(28, 40);
        }

        @include tablet-down {
            @include font-base(18, 34);
        }
    }

    &-32x44 {
        @include font-base(32, 44);

        @include break-max(1300px) {
            @include font-base(28, 49);
        }

        @include tablet-down {
            @include font-base(18, 34);
        }
    }

    &-36x48 {
        @include font-base(36, 48);

        @include tablet-down {
            @include font-base(24, 32);
        }

        @include mobile-down {
            @include font-base(20, 28);
        }
    }

    &-40x56 {
        @include font-base(40, 60);

        @include tablet-down {
            @include font-base(24, 38);
        }
    }

    &-40x52 {
        @include font-base(40, 62);

        @include tablet-down {
            @include font-base(24, 40);
        }
    }

    &-32x48 {
        @include font-base(32, 48);

        @include tablet-down {
            @include font-base(24, 32);
        }
    }

    &-32x56 {
        @include font-base(32, 56);

        @include tablet-down {
            @include font-base(24, 36);
        }
    }

    &-28x36 {
        @include font-base(28, 36);

        @include tablet-down {
            @include font-base(18, 28);
        }
    }

    &-38x45 {
        @include font-base(38, 60);

        @include tablet-down {
            @include font-base(26, 45);
        }

        @include mobile-down {
            @include font-base(24, 40);
        }
    }

    &-40x48 {
        @include font-base(40, 70);

        @include desktop-down {
            @include font-base(30, 60);
        }
    }

    &-52x70 {
        @include font-base(52, 90);

        @include desktop-down {
            @include font-base(40, 68);
        }

        @include tablet-down {
            @include font-base(35, 50);
        }
    }

    &-26x39 {
        @include font-base(26, 39);

        @include desktop-down {
            @include font-base(22, 28);
        }

        @include tablet-down {
            @include font-base(16, 24);
        }
    }

    // New
    &-35x42 {
        @include font-base(42, 58);

        @include desktop-down {
            @include font-base(38, 52);
        }

        @include tablet-down {
            @include font-base(35, 42);
        }
    }
}
