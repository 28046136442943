.o-replayComment {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 1.25rem 0;
    position: relative;
    transition: $transition ease-in-out;
    z-index: 10;
    height: 100%;
    width: 100%;
    @include scroll-bars(0, transparent);

    &_scrollDown {
        cursor: pointer;
        position: fixed;
        left: 17%;
        // transform: translateY(-5%);
        bottom: 5%;
        width: fit-content;
        animation: scrollDown 1s infinite;
        transition: all 0.3s ease-in-out;
        z-index: -1;
        opacity: 0;
        visibility: hidden;

        @include desktop-down {
            @include font-base(12, 18);
        }

        @include iphone-large-lands {
            left: 15%;
        }

        &-active {
            bottom: 18%;
            z-index: 7;
            opacity: 1;
            visibility: visible;


            @include max-desktop-down {
                bottom: 16%;
            }

            @include desktop-down {
                bottom: 15%;
            }

            @include iphone-large-lands {
                bottom: 20%;
            }
        }

        &_icon {
            border-radius: 50%;
            width: rem(60);
            height: rem(60);
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba($white, 0.8);
            border: 1px solid $white;

            @include max-desktop-down {
                width: rem(40);
                height: rem(40);
            }

            @include tablet-down {
                width: rem(30);
                height: rem(30);
            }
        }
    }

    &_loading {
        width: calc(650 / 1049 * 100%);
    }

    &_item {
        margin-top: rem(8);
        padding: rem(6) rem(22);
        border-radius: rem(20);
        color: $white;
        background-color: $black-025;
        font-family: $font-gmv-din-pro-cond;
        font-weight: normal;
        @include font-base(28, 32);
        text-align: left;
        max-width: calc(500 / 1049 * 100%);
        width: fit-content;
        word-break: break-word;

        @include max-large-desktop-down {
            @include font-base(14, 18);
        }

        @include desktop-down {
            @include font-base(12, 16);
            max-width: calc(400 / 1049 * 100%);
        }

        @include tablet-down {
            padding: rem(6) rem(12);
            max-width: calc(500 / 1049 * 100%);
        }


        @include mobile-lands {
            max-width: calc(580 / 1049 * 100%);
        }

        &:first-child {
            margin-top: 0;
        }
    }
}

// keyframes for the scroll down button
@keyframes scrollDown {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(0.5rem);
    }

    100% {
        transform: translateY(0);
    }
}
