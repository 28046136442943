.t-combo {
    padding: rem(32);
    height: 100%;

    @include max-large-desktop-down {
        padding: rem(20) rem(16);
    }

    @include max-desktop-down {
        padding: rem(16) rem(12);
    }

    @include tablet-down {
        padding: rem(12) rem(8);
    }

    @include iphone-large-lands {
        padding: rem(10) rem(7);
    }

    @include mobile-lands {
        padding: rem(8) rem(6);
    }

    @include iphone-small-lands {
        padding: rem(6);
    }

    &_note {
        width: fit-content;
        padding: rem(8) rem(16);
        border-radius: rem(10);
        border: 0.5px solid #1b9447;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $light-brilliant-red;
        font-weight: 300;
        @include font-base(24, 28);
        margin-left: auto;
        margin-top: rem(24);
        font-family: $font-castrol-sans-conml;
        margin-right: rem(32);
        cursor: pointer;

        @include max-large-desktop-down {
            @include font-base(21, 16);
            padding: rem(8) rem(16);
            border-radius: rem(8);
        }

        @include max-desktop-down {
            margin-right: rem(12);
            @include font-base(16, 21);
            padding: rem(6) rem(14);
        }

        @include desktop-down {
            border-radius: rem(6);
            @include font-base(14, 18);
            margin-top: rem(8);
            padding: rem(4) rem(12);
        }

        @include tablet-down {
            border-radius: rem(4);
            @include font-base(10, 16);
            padding: rem(2) rem(8);
            margin-top: rem(4);
        }

        &_popup {
            text-align: left;
            color: #1b9447;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-weight: 300;
            @include font-base(24, 32);
            font-family: $font-castrol-sans-conml;

            @include max-large-desktop-down {
                @include font-base(21, 28);
            }

            @include max-desktop-down {
                @include font-base(16, 24);
            }

            @include desktop-down {
                @include font-base(14, 21);
            }

            @include tablet-down {
                @include font-base(10, 16);
            }

            &_text {
                font-weight: 300;
                @include font-base(36, 48);
                margin-top: rem(24);
                font-family: $font-castrol-sans-conml;

                @include max-large-desktop-down {
                    @include font-base(24, 32);
                }

                @include max-desktop-down {
                    @include font-base(18, 24);
                    margin-top: rem(12);
                }

                @include desktop-down {
                    @include font-base(16, 21);
                }

                @include tablet-down {
                    @include font-base(12, 16);
                    margin-top: rem(8);
                }
            }
        }
    }

    &_orderNumber {
        display: flex;
        align-items: center;
        margin-bottom: rem(8);

        &_ordered,
        &_remaining {
            display: flex;
            align-items: center;
        }

        &_remaining {
            margin-left: rem(10);
        }

        &_title {
            color: $light-brilliant-red;
            font-weight: 500;
            margin-right: rem(4);
            font-family: $font-din-engschrift;
            @include font-base(28, 36);

            @include max-desktop-down {
                @include font-base(21, 28);
            }

            @include desktop-down {
                @include font-base(16, 18);
            }

            @include mobile-lands {
                @include font-base(14, 16);
            }

            @include iphone-medium-lands {
                @include font-base(13, 16);
            }

            @include iphone-small-lands {
                @include font-base(12, 14);
            }
        }

        &_number {
            width: fit-content;
            padding: rem(2) rem(12);
            border-radius: rem(10);
            border: 1px solid #009143;
            font-weight: 500;
            font-family: $font-din-engschrift;
            @include font-base(28, 36);
            min-width: rem(16);
            text-align: center;
            flex-shrink: 0;

            @include max-desktop-down {
                padding: rem(2) rem(10);
                @include font-base(21, 28);
            }

            @include desktop-down {
                @include font-base(16, 18);
                padding: rem(2) rem(4);
                border-radius: rem(4);
            }

            @include mobile-lands {
                @include font-base(14, 15);
                padding: rem(3);
            }

            @include iphone-small-lands {
                @include font-base(13, 14);
            }

            &-isOddNumberQuantities {
                color: $light-brilliant-red;
                border-color: $light-brilliant-red;
            }
        }
    }

    &_list {
        &_item {
            margin-bottom: rem(10);

            @include max-desktop-down {
                margin-bottom: rem(6);
            }
        }
    }

    &_body {
        // height: calc(100% - 46px - 38px - 96px - 55px); // has button note
        height: calc(100% - 46px - 96px - 55px);
        width: 100%;
        overflow-y: auto;

        @include desktop-down {
            // height: calc(100% - 28px - 28px - 55px - 38px);
            height: calc(100% - 28px - 55px - 38px);
        }


        @include iphone-large-lands {
            height: calc(100% - 15px - 55px - 10px);
        }

        &-cannotOrder {
            height: 100%;
        }
    }

    &_footer {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        border-bottom-left-radius: rem(20);
        border-bottom-right-radius: rem(20);
        overflow: hidden;

        @include iphone-large-lands {
            border-bottom-left-radius: rem(10);
            border-bottom-right-radius: rem(10);
        }

        &_total {
            font-family: $font-din-engschrift;
            font-weight: normal;
            @include font-base(24, 32);
            color: #009143;
            display: flex;
            flex: 1;
        }

        &_note {
            @include font-base(21, 28);
            color: $red;
            font-family: $font-din-engschrift;
            font-weight: normal;

            @include max-large-desktop-down {
                @include font-base(14, 18);
            }

            @include desktop-down {
                @include font-base(12, 16);
            }

            @include tablet-down {
                @include font-base(11, 14);
            }

            @include mobile-lands {
                @include font-base(10, 13);
            }

            @include iphone-small-lands {
                @include font-base(9, 10);
            }

            &-isOddNumberQuantities {
                color: $light-brilliant-red;
            }
        }

        &_summaryOrder {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: rem(32);
            padding-right: rem(32);

            @include max-desktop-down {
                padding-left: rem(12);
                padding-right: rem(12);
            }

            &_right {
                display: flex;
                align-items: center;
                font-family: $font-din-engschrift;
                font-weight: normal;

                &_title {
                    @include font-base(24, 32);
                    color: #009143;
                    margin-right: rem(8);

                    @include desktop-down {
                        @include font-base(12, 16);
                        margin-right: rem(4);
                    }

                    @include tablet-down {
                        @include font-base(11, 14);
                    }

                    @include iphone-large-lands {
                        margin-right: rem(2);
                    }

                    @include mobile-lands {
                        @include font-base(10, 13);
                        margin-right: rem(2);
                    }

                    @include iphone-small-lands {
                        @include font-base(9, 12);
                    }
                }

                &_total {
                    display: flex;
                    align-items: center;

                    .t-combo_orderNumber_number {
                        margin-right: rem(8);

                        @include desktop-down {
                            margin-right: rem(4);
                        }
                    }
                }

                &_unit {
                    @include font-base(24, 32);

                    @include desktop-down {
                        @include font-base(12, 16);
                    }

                    @include tablet-down {
                        @include font-base(11, 14);
                    }

                    @include mobile-lands {
                        @include font-base(10, 13);
                    }

                    @include iphone-small-lands {
                        @include font-base(9, 12);
                    }
                }
            }
        }

        &_warningQuantityFull {
            padding-left: rem(32);
            padding-right: rem(32);
            margin-bottom: rem(14);
            padding-top: rem(4);
            @include font-base(24, 32);
            color: $red;
            font-family: $font-din-engschrift;
            font-weight: normal;

            @include max-large-desktop-down {
                @include font-base(18, 21);
                margin-bottom: rem(10);
            }

            @include max-desktop-down {
                padding-left: rem(12);
                padding-right: rem(8);
            }

            @include desktop-down {
                @include font-base(14, 18);
                margin-bottom: rem(5);
            }

            @include tablet-down {
                @include font-base(12, 15);
                margin-bottom: rem(2);
            }

            @include mobile-lands {
                @include font-base(10, 13);
            }
        }
    }

    &_button {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        margin-top: rem(8);
        padding-top: rem(30);
        padding-bottom: rem(14);
        background: linear-gradient(180deg, #fff 0%, rgba(#a4a4a4, 0.2) 15.78%, rgba(#a4a4a4, 0.4) 75.79%, rgba(#a4a4a4, 0.5) 90%, #a4a4a4 100%);

        @include desktop-down {
            padding-top: rem(10);
            padding-bottom: rem(10);
        }

        @include tablet-down {
            padding-top: rem(8);
            padding-bottom: rem(8);
            margin-top: rem(2);
        }

        @include iphone-large-lands {
            padding-top: rem(6);
            padding-bottom: rem(6);
        }

        @include mobile-lands {
            padding-top: rem(4);
            padding-bottom: rem(4);
            margin-top: rem(1);
        }

        .a-button {
            width: fit-content;
        }
    }

    &_orderSuccess {
        position: relative;
        z-index: 1;
        height: 80%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        &-line1 {
            font-family: $font-din-engschrift;
            font-weight: normal;
            @include font-base(39, 47);
            text-align: center;
            color: #1b9447;

            @include max-desktop-down {
                @include font-base(28, 34);
            }

            @include desktop-down {
                @include font-base(21, 28);
            }

            @include tablet-down {
                @include font-base(16, 18);
            }
        }

        &-line2 {
            font-family: $font-din-engschrift;
            font-weight: normal;
            @include font-base(32, 36);
            text-align: center;
            color: #1b9447;
            margin-top: rem(10);

            @include max-large-desktop-down {
                @include font-base(26, 32);
            }

            @include max-desktop-down {
                @include font-base(24, 32);
                margin-top: rem(4);
            }

            @include desktop-down {
                @include font-base(21, 28);
            }

            @include tablet-down {
                @include font-base(16, 18);
            }
        }

        &-line3 {
            font-family: $font-din-engschrift;
            font-weight: normal;
            @include font-base(39, 47);
            text-align: center;
            color: #1b9447;
            margin-top: rem(40);

            @include max-large-desktop-down {
                @include font-base(32, 38);
            }

            @include max-desktop-down {
                @include font-base(28, 32);
                margin-top: rem(20);
            }

            @include desktop-down {
                @include font-base(21, 28);
                margin-top: rem(20);
            }

            @include tablet-down {
                @include font-base(16, 18);
                margin-top: rem(10);
            }
        }

        &-line4 {
            font-family: $font-gmv-din-pro-cond;
            font-weight: normal;
            color: #606060;
            @include font-base(25, 31);
            text-align: center;
            margin-top: rem(60);
            margin-bottom: rem(10);

            @include max-large-desktop-down {
                @include font-base(24, 32);
            }

            @include max-desktop-down {
                @include font-base(18, 21);
                margin-top: rem(20);
            }

            @include desktop-down {
                @include font-base(16, 18);
                margin-top: rem(20);
            }

            @include tablet-down {
                @include font-base(13, 16);
                margin-top: rem(10);
            }
        }

        &-hotline {
            max-width: rem(326);
            width: 100%;
            margin-left: auto;
            margin-right: auto;

            svg {
                width: 100%;
                height: 100%;
            }

            @include max-desktop-down {
                max-width: rem(200);
            }
        }
    }
}
