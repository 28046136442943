.p-kv {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        height: 100%;

        @include mobile-down {
            height: auto;
        }
    }
}
