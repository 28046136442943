.m-otpInput {
    $root: &;
    position: relative;

    @include mobile-down {
        margin-bottom: rem(20);
    }

    &_wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: rem(20);
    }

    .a-input {
        margin-left: rem(20);
        margin-right: rem(20);

        @include tablet-down {
            margin-left: rem(4);
            margin-right: rem(4);
        }
    }

    &_error {
        color: $ku-crimson;
        margin-top: rem(8);
    }
}
