.p-thanks {
    position: relative;

    &_bg {
        .a-image {
            @include tablet-down {
                padding-bottom: 216.26667%;
            }
        }
    }

    &_heading {
        position: absolute;
        left: 6%;
        top: 22%;
        width: rem(492);
        z-index: $z-1;

        @include break-min($standard-viewport) {
            width: 492 * $w;
        }

        @include desktop {
            left: 10%;
            top: 18%;
            width: rem(364);
        }

        @include tablet-down {
            left: 50%;
            top: 52%;
            transform: translateX(-50%);
            width: rem(684);
        }

        @include small-mobile {
            top: 50%;
            width: rem(303);
        }

        img {
            width: 100%;
        }
    }

    &_time {
        margin-top: rem(80);
    }
}
