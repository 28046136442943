.t-waiting {
    position: relative;

    &::before,
    &::after {
        content: '';
        display: block;
        pointer-events: none;
        position: absolute;
        z-index: $z-1;
    }

    &::before {
        $width: 572;
        $height: 210;
        background: transparent url('~assets/images/pop-texture111.png') no-repeat;
        background-size: cover;
        top: 0;
        left: 0;
        height: rem($height);
        width: rem($width);

        @include break-min($standard-viewport) {
            height: $height * $w;
            width: $width * $w;
        }

        @include tablet-down {
            height: rem($height - $height/3);
            width: rem($width - $width/3);
        }
        @include small-mobile {
            height: rem($height - $height/1.75);
            width: rem($width - $width/1.75);
        }
    }

    &::after {
        $size: 282;
        background: transparent url('~assets/images/pop-texture33.png') no-repeat;
        background-size: cover;
        right: 0;
        bottom: 0;
        height: rem($size);
        width: rem($size);
        transform: rotate(15deg) translate(25%, 5%);

        @include break-min($standard-viewport) {
            height: $size * $w;
            width: $size * $w;
        }
        @include small-mobile {
            height: rem($size - $size/2.5);
            width: rem($size - $size/2.5);
        }
    }

    &_wrapper {
        height: 100vh;
        min-height: rem(768);
        @include adjust-flex();
        flex-direction: column;
        position: relative;
    }

    &_logo {
        @include adjust-flex();
        margin-bottom: rem(40);
        @include mobile-down {
            margin-bottom: rem(24);
        }

        &_item {
            width: rem(168);

            @include break-min($standard-viewport) {
                width: 168 * $w;
            }

            @include mobile-down {
                width: rem(76);
            }

            &:not(:last-child) {
                margin-right: rem(16);
            }
        }
    }

    &_title {
        max-width: rem(830);
        margin: 0 auto;

        .a-text {
            @include text-background($linear-seal-brown);
        }

        @include tablet-down {
            .a-text {
                @include font-base(20, 32); }
        }
    }

    &_subTitle,
    &_content {
        margin-top: rem(32);

        @include tablet-down {
            margin-top: rem(16);

            .mt-2 {
                margin-top: 0 !important;
            }
        }
    }

    &_footer {
        position: absolute;
        right: 0%;
        bottom: 4%;
        width: fit-content;
        height: fit-content;

        .a-text {
            text-align: right;
        }
        @include break-min($standard-viewport) {
            bottom: 5%;
        }
    
        @include desktop-down {
            right: 5%;
        }
        @include small-mobile {
            right: 10%;
            bottom: 3%;
        }
    }
}
