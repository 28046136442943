.a-button {
    $root: &;
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin: 0;
    outline: none;
    position: relative;
    text-transform: uppercase;
    transition: $transition-long ease;
    @include font-base(53, 64);
    width: fit-content;
    border-radius: rem(14);
    background: linear-gradient(#8a8a8a 0%, #fff 15.78%, #fff 75.79%, #a4a4a4 100%);
    font-family: $font-din-engschrift;
    font-weight: normal;
    text-align: center;
    color: $north-texas-green;
    padding: rem(4) rem(52);
    border: rem(2) solid #707070;

    @include max-large-desktop-down {
        @include font-base(42, 53);
    }

    @include max-desktop-down {
        border-radius: rem(12);
        padding: rem(4) rem(40);
        border-width: rem(1.5);
    }

    @include tablet-down {
        @include font-base(22, 26);
        border-radius: rem(8);
        border: 0;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 50%;
        width: 82%;
        height: 2px;
        background: linear-gradient(rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
        z-index: 1;
        transition: 0.4s ease;
        border-radius: 50%;
        transform: translate(-50%, 0);
        box-shadow: 0 2px 3px 2px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(18, 13, 13, 0.19);
    }

    &:active {
        transform: scale(0.95);
    }

    &_icon {
        margin-right: rem(3);
        width: rem(16);
        height: rem(16);
    }

    &-loading {
        pointer-events: none;

        &::before {
            background: $turquoise;
        }
    }

    &-disabled {
        color: rgba($dark-cerulean, 0.7);
        filter: grayscale(1);
        cursor: not-allowed;
    }

    &_loadingIcon {
        position: relative;
        width: rem(30);
        z-index: $z-2;

        @include tablet-down {
            width: rem(18);
            height: rem(16);
        }
    }

    &-icon-bigger {
        font-family: $font-din-engschrift;
        font-weight: normal;
        @include font-base(24, 32);
        text-align: left;
        color: #ea1c27;
        border-radius: rem(13);
        padding: rem(10) rem(12) rem(10) rem(48);
        border: rem(4) solid $white;
        text-transform: inherit;

        @include max-desktop-down {
            @include font-base(21, 28);
        }

        @include desktop-down {
            @include font-base(16, 21);
        }

        @include tablet-down {
            @include font-base(12, 17);
            border: 1.16px solid $white;
        }
    }

    &-medium {
        font-family: $font-din-engschrift;
        font-weight: normal;
        @include font-base(24, 32);
        width: 100%;
        border-radius: rem(8);

        @include max-desktop-down {
            @include font-base(21, 28);
        }

        @include desktop-down {
            @include font-base(16, 21);
        }

        @include tablet-down {
            @include font-base(12, 17);
            border: rem(1) solid #707070;
        }

        @include iphone-large-lands {
            @include font-base(11, 14);
            padding: rem(4) rem(24);
        }

        @include mobile-lands {
            @include font-base(10, 13);
        }
    }

    &_icon-warning {
        width: rem(61);
        height: rem(61);
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: rem(-20);
        left: rem(-20);
        background-color: #ea1c27;
        border-radius: 50%;
    }
}
