.t-registerInstruction {
    &_heading {
        width: fit-content;
        margin: 0 auto;
        border: 0;
        border-radius: rem(24);

        .a-heading {
            @include text-background($linear-black-cerulean5);
            position: relative;
            z-index: 3;
        }

        @include tablet-down {
            max-width: 90%;
            margin: 0 auto;
        }
    }

    &_headingWrapper {
        background: $linear-orange-title;
        padding: rem(12) rem(24) 0;
        border-radius: rem(24);
        position: relative;

        &::before {
            content: '';
            background: var(--dkngaystroke, $mellow-apricot);
            position: absolute;
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            border-radius: rem(24);
            top: -2px;
            left: -2px;
            z-index: 1;
        }

        &::after {
            content: '';
            background: $linear-orange-title;
            width: 100%;
            height: 100%;
            border-radius: rem(24);
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
        }
    }

    &_content {
        margin: rem(32) auto 0;
        @include adjust-flex(space-between, center);

        @include tablet-down {
            margin-top: rem(12);
            flex-direction: column;
        }

        ul {
            list-style-type: decimal;
            padding: rem(19) 0 rem(19) rem(32);
            @include font-base(32, 48);
            color: $pattrick-blue;

            @include tablet-down {
                @include font-base(20, 32);
                padding: 0;
                padding-left: rem(24);
            }

            @media (max-width: 374px) {
                @include font-base(16, 24);
            }

            li {
                &:not(:first-child) {
                    margin-top: rem(12);

                    @include mobile-down {
                        margin-top: rem(8);
                    }
                }
            }

            .a-text,
            a {
                font-size: inherit;
                color: inherit;
            }
        }
    }

    &_qrcode {
        width: 100%;

        @include tablet-up {
            margin-left: rem(40);
            max-width: rem(232);
        }

        @include tablet-down {
            max-width: rem(160);
            margin-top: rem(18);
            margin-left: auto;
            margin-right: auto;
        }
    }
}
